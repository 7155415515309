@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
	scroll-behavior: smooth;
}

body {
	min-height: 100vh;
	height: 100%;
	font-family: 'Raleway', sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: @color2;

	&.asd {
		display: none;
	}

	&.no-scroll {
		overflow: hidden;
		max-height: 100vh;
	}

	.btn {
		padding: 10px 42px;
		text-transform: uppercase;
		font-size: 16px;
		font-weight: 700;
		border-radius: 0;
		transition: all 0.5s;

		&.btn-default {
			border: 1px solid #000;
			color: #000;

			&:hover {
				background: #000;
				color: #fff;
			}
		}

		&.btn-white {
			border: 1px solid #fff;
			color: #fff;

			&:hover {
				background: #fff;
				color: #000;
			}
		}

		&.btn-empty {
			border-color: transparent;

			&:hover {
				background: none;
				color: @color3;
			}
		}

		&.btn-small {
			padding: 7px 35px;
			font-size: 12px;
		}
	}

	ul, ol, p, h1, h2, h3, h4, h5, h6 {
		margin: 0;
		padding: 0;
	}

	a {
		text-decoration: none;
	}
}

@color1: #E5C5C5;
@color2: #F3F2ED;
@color3: darken(#E5C5C5, 15%);

.alert-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.2);
	z-index: 555;
	opacity: 0;
	visibility: hidden;
	transition: all 0.5s;

	.alert-service {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-height: 100vh;
		width: 100%;
		transform: translateX(8px);
		padding: 50px 0;

		.service-inner {
			padding: 50px;
			background: #fff;
			width: 100%;
			box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
			text-align: center;
			border-top: 5px solid #fff;
			position: relative;
			transform: scale(0.8);
			transition: all 0.5s;

			.inner-dismiss {
				top: 10px;
				right: 10px;
				position: absolute;
				border: 0;
				background: none;
				padding: 0;
				cursor: pointer;
				display: flex;
				padding: 5px;
				outline: none;

				img {
					height: 15px;
				}
			}

			&.success {
				border-top: 5px solid #78b042;
			}

			&.warning {
				border-top: 5px solid #d51a27;
			}

			&.info {
				border-top: 5px solid #1a88d5;
			}
		}
	}

	&.active {
		opacity: 1;
		visibility: visible;

		.alert-service {
			.service-inner {
				transform: none;
			}
		}
	}
}

.modal-service-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.5);
	z-index: 555;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;

	&.active {
		opacity: 1;
		visibility: visible;
	}

	.modal-service {
		padding: 50px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100%;

		.service-inner {
			background: #fff;
			width: 700px;
			max-width: 100%;
			padding: 50px 25px 25px 25px;
			text-align: center;
			position: relative;

			.service-button {
				position: absolute;
				top: 20px;
				right: 20px;
				border: none;
				background: none;
				padding: 15px;
				display: flex;
				justify-content: center;
				align-items: center;

				.button-bar {
					display: block;
					position: absolute;
					width: 20px;
					height: 2px;
					background: #222;

					&:nth-child(1) {
						transform: rotate(45deg);
					}

					&:nth-child(2) {
						transform: rotate(-45deg);
					}
				}
			}

			.service-content {
				.heading {
					font-size: 16px;
					font-weight: 700;
					text-transform: uppercase;
					margin-bottom: 20px;
				}

				.btn {
					display: flex;
					justify-content: center;
					margin-top: 5px;
				}
			}

			.service-buttons {
				margin-top: 30px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				
				.btn-link {
					padding: 0;
					text-transform: uppercase;
					font-weight: 700;
					text-decoration: none;
				}
			}
		}
	}
}

.products-flexbox {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	.product-service {
		margin-top: 3.5%;
		width: 23.5%;
		position: relative;
		text-align: center;
		transition: all 0.5s;

		.service-image-wrapper {
			width: 100%;
			padding-top: 100%;
			position: relative;
			display: block;
			transition: all 0.5s;
			position: relative;
			background: #fff;

			.service-image {
				text-align: center;
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				display: flex;
				align-items: center;
				overflow: hidden;

				img {
					top: 10%;
					left: 10%;
					width: 80%;
					height: 80%;
					position: absolute;
					object-fit: contain;
					object-position: center;
					transition: all 0.5s;
				}
			}
		}

		.name {
			margin-top: 15px;
			color: #000;
			font-weight: 300;
			text-transform: uppercase;
			font-size: 11px;
			transition: all 0.5s;
		}

		.code {
			color: #000;
			font-weight: 600;
			font-size: 16px;
			text-transform: uppercase;
			transition: all 0.5s;
		}

		.btn {
			margin-top: 25px;
		}

		&:hover {
			.service-image-wrapper {
				.service-image {
					img {
						transform: scale(1.05);
					}
				}
			}
		}

		&.service-hidden {
			margin: 0 !important;
			padding: 0 !important;
			border: none !important;
			visibility: hidden;
			opacity: 0;
		}
	}

	&.small-margin {
		.product-service {
			margin-top: 2%;
		}
	}
}

.return-to-top {
	position: fixed;
	z-index: 333;
	bottom: 15px;
	right: 15px;
	transition: all 0.3s;
	border-radius: 50%;
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.5s;
	visibility: hidden;
	background: darken(@color1, 10%);
	opacity: 0;

	img, svg {
		height: 45%;
		width: 45%;
	}

	svg {
		fill: #fff;
	}

	&.active {
		opacity: 1;
		visibility: visible;
	}

	&:hover {
		background: darken(@color1, 20%);
	}
}

.dynamic-text-wrapper {
	h1, h2, h3, h4, h5, h6 {
		color: #000;
		font-weight: 500;

		b, strong {
			font-weight: 700;
		}
	}

	p, li {
		color: #000;

		b, strong {
			font-weight: 700;
		}
	}

	h1 {
		font-size: 48px;
	}

	h2 {
		font-size: 48px;
	}

	h3 {
		font-size: 34px;
	}

	h4 {
		font-size: 28px;
	}

	h5 {
		font-size: 24px;
	}

	h6 {
		font-size: 21px;
	}

	p, li {
		font-size: 16px;
	}

	a {
		&:not(.btn) {
			color: @color3;
			text-decoration: underline;
		}
	}

	ul {
		padding-left: 17px;
		list-style: none;

		li {
			position: relative;

			&:before {
				content: '';
				position: absolute;
				top: 7px;
				left: -17px;
				height: 12px;
				width: 12px;
				background: url('../img/icons/point.svg') center;
				background-size: contain;
				background-repeat: no-repeat;
			}

			& + li {
				margin-top: 8px;
			}
		}
	}

	ol {
		padding-left: 17px;

		li {
			& + li {
				margin-top: 15px;
			}
		}
	}

	img {
		max-width: 100%;
		border-radius: 3px;
	}

	table {
		width: 100%;

		tr {
			td {
				padding: 10px;
				color: #000;
				font-size: 16px;
				border: none;
				transition: all 0.5s;

				p {
					color: inherit;
				}

				b, strong {
					font-weight: 700;
					color: #000;
				}

				&:first-child {
					border-radius: 8px 0 0 8px;
				}

				&:last-child {
					border-radius: 0 8px 8px 0;
				}
			}

			&:hover {
				td {
					background: darken(@color2, 10%);
				}
			}
		}
	}

	.accordion {
		width: 100%;

		.accordion-item {
			.accordion-header {
				.accordion-button {
					color: @color1;
					font-size: 28px;
					font-weight: 700;
					box-shadow: none;
					text-align: left;
					border: none;
					padding: 0 0 10px 0;
					background: none;

					span {
						padding-right: 50px;
						display: block;
						position: relative;
						width: 100%;

						&:after {
							position: absolute;
							background: none;
							content: '-';
							font-size: 22px;
							transform: translateY(-4px);
							top: 0;
							right: 7px;
						}
					}

					&.collapsed {
						color: #000;

						span {
							&:after {
								content: '+';
							}
						}
					}

					&:after {
						content: none;
					}
				}
			}

			.accordion-collapse {
				border: none;
			}

			.accordion-body {
				padding: 20px 0 10px 0;
			}

			& + .accordion-item {
				margin-top: 20px;
			}
		}
	}
}

.btn-more-products-wrapper {
	margin-top: 120px;

	.more-products-counter {
		margin-bottom: 35px;

		.number {
			font-size: 12px;
			color: #000;
			margin-bottom: 10px;
		}

		.line {
			margin: 0 auto;
			width: 170px;
			height: 4px;
			border-radius: 10px;
			outline: 0.5px solid #000;
			position: relative;

			.line-inner {
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				background: #000;
			}
		}
	}
}

.input-service {
	margin-top: 15px;

	label {
		font-size: 9px;
		font-weight: 700;
		letter-spacing: 1px;
		text-transform: uppercase;
		padding-left: 0;
		color: #000;
		text-align: left;
		width: 100%;
	}

	input, textarea {
		width: 100%;
		display: block;
		border: none;
		height: 45px;
		color: #000;
		font-weight: 500;
		font-size: 13px;
		width: 100%;
		border-radius: 0;
		padding: 0;
		background: none;
		border-bottom: 1px solid #000;
		transition: all 0.5s;

		&:focus {
			outline: none;
			box-shadow: none;
			border-color: @color3;
		}

		&.warning {
			box-shadow: none;
		}
	}

	input::placeholder {
		font-weight: 500;
		font-size: 13px;
	}

	.input-alert {
		margin-top: 7px;
		font-size: 11px;
		color: #dc3545;
	}

	.value {
		color: #000;
		font-size: 15px;
		text-align: left;
	}

	.textarea-value {
		padding: 15px;
		background: #f9f9f9;
		border-radius: 2px;
		color: #000;
		font-size: 14px;
		font-weight: 300;
	}

	&.service-alert {
		label {
			color: #dc3545;
		}

		input, textarea {
			border-color: #dc3545;
		}
	}

	textarea {
		height: 150px;
		border-radius: 5px;
	}
}

.select-wrapper {
	label {
		font-size: 9px;
		font-weight: 700;
		letter-spacing: 1px;
		text-transform: uppercase;
		padding-left: 0;
		color: #000;
		text-align: left;
		width: 100%;
	}

	.select-inner {
		position: relative;
		overflow: hidden;
		border-bottom: 1px solid #000;

		select {
			cursor: pointer;
			padding: 6px 25px 5px 0;
			border: none;
			background: none;
			height: auto;
			-moz-appearance: none;
			-webkit-appearance: none;
			appearance: none;
			outline: none;
			display: block;
			font-weight: 500;
			font-size: 13px;
			width: 100%;
		}

		svg {
			position: absolute;
			height: 8px;
			min-width: 8px;
			max-width: 8px;
			right: 0;
			top: 50%;
			transform: translateY(-50%) rotate(-90deg);
		}
	}
}

.checkbox-wrapper {
	margin-top: 20px;
	cursor: pointer;
	display: block;
	position: relative;

	.radio-box {
		display: flex;
		text-align: left;

		p {
			margin-left: 10px;
			margin-top: -1px;
			color: #000;
			font-weight: 500;
			font-size: 13px;

			a {
				color: @color3;
				text-decoration: underline;
			}
		}

		.box-icon {
			display: block;
			height: 15px;
			min-width: 30px;
			max-width: 30px;
			border-radius: 20px;
			border: 1px solid #000;
			position: relative;

			.icon-ball {
				height: 9px;
				width: 9px;
				border-radius: 50%;
				position: absolute;
				left: 3px;
				top: 2px;
				background: #000;
				transition: all 0.5s;
			}
		}
	}

	input {
		visibility: visible;
		opacity: 0;
		left: 0;
		position: absolute;
		width: auto !important;
	}

	&.checkbox-alert {
		.radio-box {
			p {
				color: #dc3545;			
			}

			.box-icon {
				border-color: #dc3545;
			}
		}
	}

	& + .checkbox-wrapper {
		margin-top: 10px;
	}
}

.checkbox-wrapper > input:checked + .radio-box {
	.box-icon {
		border-color: @color3;

		.icon-ball {
			left: 16px;
			background: @color3;
		}
	}
}

.checkbox-wrapper > input:focus-visible + .radio-box {
	outline: 2px solid #000;
}

.modal {
	.modal-dialog {
		.btn-close {
			opacity: 0.85;
		}

		* {
			border-radius: 0;

			&:not(.btn-close) {
				background: @color2;
			}
		}

		.modal-content {
			border: none;

			img {
				max-width: 100%;
				border-radius: 5px;
			}
		}
	}
}

.header-sidebar {
	display: none;
	position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	background: rgba(0, 0, 0, 0.2);
	z-index: -1;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	transition: all 0.4s;

	.sidebar-service {
		position: absolute;
		height: 100%;
		width: 400px;
		max-width: 100%;
		right: 0;
		top: 0;
		box-shadow: -10px 5px 35px 0px rgba(0, 0, 0, 0.15);
		transform: translateX(100%);
		transition: all 0.4s;

		.service-heading {
			padding: 40px;
			min-height: 116px;
			display: flex;
			justify-content: space-between;
			color: #000;

			.heading {
				font-size: 24px;
				line-height: 28px;
				font-weight: 400;
			}

			.delivery {
				text-align: right;
				font-size: 13px;
				line-height: 18px;
			}
		}

		@z-index-value: 222;

		.service-menu-wrapper {
			position: absolute;
			background: #fff;
			top: 0;
			left: 0;
			transition: all 0.6s;
			z-index: @z-index-value;
			height: 100vh;
			width: 100%;

			&.hidden {
				transform: translateX(100%);
				z-index: 1;
			}
		}

		.service-menu-wrapper + .service-menu-wrapper {
			z-index: @z-index-value * 2;
		}

		.service-menu {
			width: 400px;
			max-width: 100%;
			background: #f1f1f1;
			padding: 20px;
			height: 100%;
			max-height: 100vh;

			.backlink {
				display: block;
				outline: none;
				padding: 15px 20px;
				width: 100%;
				background: #fff;
				border: none;

				.backlink-inner {
					display: flex;
					align-items: center;

					span {
						color: #000;
						font-weight: 600;
					}

					img {
						margin-right: 15px;
						transform: rotate(180deg);
						height: 10px;
					}
				}

				&.backlink-close {
					position: absolute;
					top: 0;
					left: 0;
					background: #fff;

					&.hidden {
						display: none;
					}
				}
			}

			.menu-heading {
				border-top: 45px solid #f1f1f1;
				font-size: 18px;
				font-weight: 600;
				text-align: center;
				padding: 15px 20px;
				color: #000;
			}

			.menu-list {
				list-style: none;
				border-top: 45px solid #f1f1f1;

				li {
					a, button {
						padding: 15px 50px 15px 0;
						width: 100%;
						position: relative;
						border: none;
						outline: none;
						display: flex;
						align-items: center;

						.icon {
							fill: #000;
							height: 20px;
							min-width: 20px;
							max-width: 20px;
							margin-right: 10px;
						}

						span {
							color: #000;
							font-weight: 600;
							transform: translateY(2px);
						}

						&.has-child {
							&:after {
								content: '';
								height: 10px;
								min-width: 10px;
								max-width: 10px;
								position: absolute;
								right: 20px;
								top: 50%;
								transform: translateY(-50%);
								background: url(../img/icons/right-chevron.svg) center;
								background-size: cover;
							}
						}

						&.highlight-border {
							padding-right: 15px;
							padding-left: 15px;
							background: #fff;
							border: 2px solid @color1;
							text-align: center;
							justify-content: center;
						}
					}

					&:not(:last-child) {
						border-bottom: 0.5px solid #ccc;
					}
				}

				&.no-border {
					li {
						&:not(:last-child) {
							border-bottom: none;
							margin-bottom: 10px;
						}
					}
				}
			}

			.menu-heading + .menu-list {
				border-top: 0.5px solid #ccc;
			}

			.menu-social-media {
				border-top: 45px solid #f1f1f1;
				display: flex;
				align-items: center;

				li {
					p {
						margin-right: 5px;
						font-size: 12px;
						text-transform: uppercase;
						font-weight: 600;
						color: #000;
					}

					a {
						display: flex;
						align-items: center;

						svg, img {
							fill: #000;
							height: 24px;
							width: 24px;
							transition: all 0.5s;
						}

						&:hover {
							svg, img {
								fill: @color3;
							}
						}
					}

					& + li {
						margin-left: 7px;
					}
				}
			}

			.menu-filters {
				margin-top: 50px;

				.heading-filters {
					.input-service {
						margin-top: 0;

						input {
							height: 31.5px;
						}
					}

					.checkbox-wrapper {
						&:first-child {
							margin-top: 13px;
						}
					}

					> * {
						& + * {
							margin-top: 22px;
						}
					}
				}

				.buttons-wrapper {
					margin-top: 50px;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					flex-direction: row-reverse;
				}
			}
		}

		&.active {
			transform: translateX(0);
		}
	}

	&.active {
		z-index: 999;
		visibility: visible;
		opacity: 1;
	}
}

.cookies-alert {
	position: fixed;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 888;
	width: 330px;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 2px;
	padding: 15px 40px 15px 20px;
	transition: all 0.4s;

	.btn-close {
		position: absolute;
		top: 12px;
		right: 6px;
		outline: none;
		box-shadow: none;
		height: auto;
		width: auto;
		transition: opacity 0.4s;
		border: none;
		background: none;
		position: absolute;
		background: none;
		padding: 10px;
		cursor: pointer;

		span {
			position: absolute;
			display: block;
			left: 0;
			top: 50%;
			width: 12px;
			height: 1px;
			background: #fff;

			&:nth-child(1) {
				transform: rotate(45deg) translateY(-50%);
			}

			&:nth-child(2) {
				transform: rotate(-45deg) translateY(-50%);
			}
		}

		&:after {
			content: none;
		}
	}

	p {
		font-size: 11px;
		font-weight: 300;
		color: #fff;

		a {
			color: inherit;
			text-decoration: underline;
		}
	}

	&.hidden {
		visibility: hidden;
		opacity: 0;
	}
}

nav.section-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 888;
	transition: all 0.5s;

	.header-flexbox {
		padding: 25px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		transition: all 0.5s;

		.flexbox-logo {
			img, svg {
				height: 80px;
				fill: #000;
				transition: all 0.5s;
			}
		}

		.flexbox-inner {
			display: flex;
			align-items: center;

			> * {
				& + * {
					margin-left: 50px;
				}
			}

			.flexbox-nav {
				display: flex;
				align-items: center;

				li {
					> a {
						font-size: 17px;
						font-weight: 600;
						color: #000;
						transition: all 0.5s;
					}

					.dropdown {
						> a, button {
							padding: 0;
							border: none;
							background: none;
							outline: none;

							svg {
								margin-left: 10px;
								fill: #000;
								height: 6px;
								min-width: 6px;
								max-width: 6px;
								transform: rotate(90deg);
							}

							span {
								font-size: 17px;
								font-weight: 600;
								color: #000;
								transition: all 0.5s;
							}
						}

						.dropdown-menu {
							margin-top: 5px !important;
							background: rgba(255, 255, 255, 0.9);
							border-radius: 0;
							min-width: 80px;
							border: none;

							li {
								position: relative;

								a, button {
									padding: 5px 15px;
									font-size: 13px;
									color: #000;
									font-weight: 600;

									span {
										font-size: inherit;
									}

									&:active {
										background: #ccc;
									}

									svg {
										transform: none;
									}
								}

								& + li {
									margin-left: 0;
								}
							}

							> li {
								&:hover {
									background-color: #f1f1f1;

									> .submenu {
										display: block;
									}
								}
							}

							.submenu { 
								display: none;
								position: absolute;
								left: 100%;
								top: -7px;

								&.submenu-left {
									right: 100%;
									left: auto;
								}
							}
						}
					}

					& + li {
						margin-left: 40px;
					}
				}
			}

			.flexbox-social-media {
				display: flex;
				align-items: center;

				li {
					a {
						display: flex;
						align-items: center;

						svg, img {
							fill: #000;
							height: 24px;
							width: 24px;
							transition: all 0.5s;
						}
					}

					& + li {
						margin-left: 7px;
					}
				}
			}
		}

		.nav-button {
			padding: 10px;
			border: none;
			background: none;
			flex-direction: column;
			align-items: flex-end;
			justify-content: center;
			outline: none;
			display: none;
			
			.button-bar {
				height: 2px;
				width: 30px;
				background: #000;
				transition: all 0.5s;

				&:nth-child(2) {
					margin-top: 8px;
				}

				&:nth-child(3) {
					margin-top: 8px;
				}
			}

			&.button-white {
				.button-bar {
					background: #000 !important;
				}
			}
		}
	}

	&.scrolled {
		background: rgba(255, 255, 255, 0.75);

		.header-flexbox {
			padding: 10px 0;

			.flexbox-logo {
				img, svg {
					height: 60px;
				}
			}

			.flexbox-inner {
				.flexbox-nav {
					li {
						> a {
							font-size: 16px;
						}

						.dropdown {
							> a, button {
								span {
									font-size: 16px;
								}
							}
						}
					}
				}

				.flexbox-social-media {
					li {
						a {
							svg, img {
								height: 20px;
								width: 20px;
							}
						}
					}
				}
			}
		}

		&:hover {
			background: rgba(255, 255, 255, 0.9);
		}
	}

	&.white {
		.header-flexbox {
			.flexbox-logo {
				img, svg {
					fill: #fff;
				}
			}

			.flexbox-inner {
				.flexbox-nav {
					li {
						> a {
							color: #fff;
						}

						.dropdown {
							> a, button {
								svg {
									fill: #fff;
								}

								span {
									color: #fff;
								}
							}
						}
					}
				}

				.flexbox-social-media {
					li {
						a {
							svg, img {
								fill: #fff;
							}
						}
					}
				}

				.nav-button {
					.button-bar {
						background: #fff;
					}
				}
			}
		}

		&.scrolled {
			background: rgba(0, 0, 0, 0.75);

			&:hover {
				background: rgba(0, 0, 0, 0.9);
			}
		}
	}
}

section.section-welcome {
	.welcome-item {
		position: relative;

		.item-description {
			position: relative;
			z-index: 111;

			.description-text {
				min-height: 100vh;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;

				h1, h2 {
					font-size: 48px;
					font-weight: 500;
					color: #fff;

					b, strong {
						font-weight: 700;
						font-size: 60px;
					}
				}

				.btn {
					margin-top: 100px;
				}
			}
		}

		.item-background {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			video, img {
				position: relative;
				object-fit: cover;
				object-position: center;
				height: 100%;
				width: 100%;
			}
		}
	}
}

.section-heading {
	h1, h2, .heading-inner {
		font-size: 48px;
		font-weight: 500;
		color: #000;
		letter-spacing: -2px;
	}

	p {
		margin-top: 30px;
		font-size: 20px;
		color: #000;
	}

	.btn {
		margin-top: 60px;
	}

	&.white {
		h1, h2 {
			color: #fff;
		}

		p {
			color: #fff;
		}
	}
}

section.section-offer {
	padding-top: 150px;

	.offer-inner {
		margin-top: 150px;
		padding-right: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.inner-service {
			display: block;

			.service-inner {
				padding-top: 130%;
				position: relative;
				transition: all 0.5s;

				.inner-border {
					position: absolute;
					top: 50px;
					left: 50px;
					width: 100%;
					height: 100%;
					border: 2px solid @color1;
					transition: all 0.5s;
				}

				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-position: center;
					object-fit: cover;
				}

				.inner-name {
					font-weight: 700;
					font-size: 50px;
					color: #fff;
					position: absolute;
					text-transform: uppercase;
					bottom: 40px;
					left: 50%;
					transform: translateX(-50%);
				}

				&:hover {
					transform: translate(25px, 25px);

					.inner-border {
						transform: translate(-65px, -65px);
						height: calc(~'100% + 30px');
						width: calc(~'100% + 30px');
					}
				}
			}

			&:nth-child(1) {
				.service-inner {
					width: 520px;
				}
			}

			&:nth-child(2) {
				.service-inner {
					width: 430px;
				}
			}
		}
	}
}

section.section-slogan {
	padding-top: 200px;
}

section.section-wholesale {
	padding-top: 150px;
	overflow: hidden;

	.wholesale-inner {
		display: flex;
		align-items: center;

		.inner-description {
			white-space: nowrap;
			margin-right: 100px;
		}

		.inner-gallery {
			max-width: 1350px + (2*30px);

			.gallery-image {
				height: 450px;
				width: 450px;

				img {
					height: 100%;
					width: 100%;
					object-position: center;
					object-fit: cover;
				}
			}

			.swiper {
				position: relative;

				.swiper-buttons {
					position: absolute;
					display: flex;
					flex-direction: row-reverse;
					bottom: 12px;
					left: 12px;
					z-index: 999;

					.swiper-button-next, .swiper-button-prev {
						pointer-events: auto;
						background-color: darken(@color2, 10%);
						border-radius: 50%;
						background-image: url(../img/icons/chevron.svg);
						background-position: center;
						background-size: 25%;
						background-repeat: no-repeat;
						padding: 0;
						margin: 0;
						display: block;
						height: 50px;
						width: 50px;
						outline: none;
						position: relative;
						left: auto;
						right: auto;
						top: auto;
						bottom: auto;

						&:after {
							content: none;
						}
					}

					.swiper-button-next {
						margin-left: 12px;
						transform: rotate(-180deg);
					}
				}
			}
		}
	}
}

section.section-about {
	padding-top: 150px;

	.about-inner {
		display: flex;
		justify-content: center;
		align-items: center;

		.inner-image {
			min-width: 400px;
			max-width: 400px;
			margin-right: 100px;

			img {
				width: 100%;
			}
		}
	}

	.about-advantages {
		margin-top: 150px;
		display: flex;
		justify-content: space-around;

		&.small-margin {
			margin-top: 80px;
		}

		.advantage-service {
			text-align: center;

			.service-icon {
				img {
					height: 55px;
				}
			}

			.service-name {
				margin-top: 20px;
				font-size: 14px;
				text-transform: uppercase;
				color: #000;
			}
		}
	}
}

section.section-products {
	margin-top: 150px;
	padding: 150px 0;
	background: #fff;

	.owl-carousel {
		margin-top: 100px;

		.owl-nav {
			position: absolute !important;
			margin-top: 0;
			top: 40%;
			left: 0;
			transform: translateY(-50%);
			width: 100%;
			pointer-events: none;
			justify-content: space-between;
			display: flex;
			align-items: center;

			.owl-prev, .owl-next {
				pointer-events: auto;
				background-color: darken(@color2, 10%);
				border-radius: 50%;
				background-image: url(../img/icons/chevron.svg);
				background-position: center;
				background-size: 25%;
				background-repeat: no-repeat;
				padding: 0;
				margin: 0;
				display: block;
				height: 50px;
				width: 50px;
				outline: none;
			}

			.owl-next {
				transform: rotate(-180deg);
			}
		}
	}

	.products-flexbox {
		width: 410px;
		transform: scale(0.75);
		transition: all 0.5s;

		.product-service {
			margin-top: 0;
			width: 100%;

			.btn {
				opacity: 0;
			}
		}
	}

	.owl-item {
		&.active.center {
			.products-flexbox {
				transform: scale(1);

				.product-service {
					.btn {
						opacity: 1;
					}
				}
			}
		}
	}
}

section.section-video {
	padding: 200px 0;
	position: relative;

	video {
		position: absolute;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
		height: 100%;
		width: 100%;
	}

	.section-heading {
		position: relative;
	}
}

section.section-partnership {
	padding-top: 150px;

	.partnership-inner {
		display: flex;
		justify-content: center;
		align-items: center;

		.inner-image {
			min-width: 270px;
			max-width: 270px;
			margin-left: 180px;

			img {
				width: 100%;
			}
		}
	}
}

section.o-firmie-section-content {
	padding-top: 230px;

	.content-history {
		padding-top: 150px;

		.nav-tabs {
			margin-top: 80px;
			border: none;
			display: flex;
			align-items: center;
			justify-content: center;

			.nav-item {
				display: flex;
				align-items: center;

				.nav-link {
					border: none;
					font-size: 20px;
					font-weight: 500;
					color: #000;
					letter-spacing: -1px;
					background: none;
					outline: none;
					padding: 0;
					border-radius: 0;
					transition: all 0.5s;

					&.active {
						color: @color3;	

						& + .divider {
							background: @color3;

							&:before, &:after {
								background: @color3;
							}
						}					
					}
				}

				.divider {
					margin-top: 2px;
					margin-left: 25px;
					margin-right: 25px;
					width: 100px;
					height: 2px;
					background: #000;
					position: relative;
					transition: all 0.5s;

					&:before, &:after {
						content: '';
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						height: 8px;
						width: 8px;
						border-radius: 50%;
						background: #000;
						transition: all 0.5s;
					}

					&:before {
						left: 0;
					}

					&:after {
						right: 0;
					}
				}

				&:last-child {
					.divider {
						display: none;
					}
				}
			}
		}

		.tab-content {
			margin-top: 80px;

			.content-inner {
				display: flex;
				align-items: center;

				.inner-description {
					margin-right: 150px;
				}

				.inner-image {
					min-width: 300px;
					max-width: 300px;

					img {
						width: 100%;
					}
				}
			}
		}
	}

	.content-management {
		padding-top: 150px;

		.management-inner {
			margin-top: 100px;
			padding: 0 125px;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.inner-service {
				width: 41%;
				text-align: center;
				border: none;
				outline: none;
				padding: 0;
				background: none;

				.service-image {
					position: relative;
					width: 100%;
					padding-top: 125%;

					.image-border {
						position: absolute;
						top: 50px;
						left: 50px;
						width: 100%;
						height: 100%;
						border: 2px solid @color1;
						transition: all 0.5s;
					}

					img {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center;
					}
				}

				.name {
					margin-top: 90px;
					color: #000;
					font-size: 24px;
					font-weight: 500;
				}

				.job {
					margin-top: 5px;
					color: #000;
					font-size: 20px;
					font-weight: 500;
				}

				.info {
					margin-top: 25px;
					color: #000;
				}

				.btn {
					margin-top: 25px;
				}
			}
		}

		& + .content-text-photo {
			margin-top: 150px;
		}
	}

	.content-abroad {
		padding: 200px 125px 0 125px;

		p {
			max-width: 800px;
			margin: 0 auto;
		}

		img {
			margin-top: 50px;
			width: 100%;
		}
	}

	.content-about {
		padding: 200px 125px 0 125px;

		p {
			max-width: 800px;
			margin: 0 auto;
		}

		.about-advantages {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.advantage-service {
				margin-top: 80px;
				width: 25%;
				text-align: center;

				.service-icon {
					img {
						height: 55px;
					}
				}

				.service-name {
					margin-top: 20px;
					font-size: 14px;
					text-transform: uppercase;
					color: #000;
				}
			}
		}
	}

	.content-csr {
		padding: 150px 125px 0 125px;

		p {
			max-width: 800px;
			margin: 0 auto;
		}

		.csr-inner {
			margin-top: 100px;
			display: flex;
			justify-content: space-between;

			.inner-service {
				width: 41%;

				.service-image {
					position: relative;
					width: 100%;
					padding-top: 125%;
					margin-bottom: 90px;

					.image-border {
						position: absolute;
						top: 50px;
						left: 50px;
						width: 100%;
						height: 100%;
						border: 2px solid @color1;
						transition: all 0.5s;
					}

					img {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center;
					}
				}

				h2 {
					color: #000;
					font-size: 24px;
					font-weight: 500;
				}

				h3 {
					color: #000;
					font-size: 20px;
					font-weight: 500;
				}

				&:nth-child(2) {
					margin-top: 100px;
				}
			}
		}
	}

	.content-cta {
		margin-top: 150px;
		padding: 150px 0;
		background: #fff;

		.btn {
			margin-top: 80px;
		}
	}
}

section.kategoria-section-content {
	padding-top: 170px;

	.content-categories {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;

		.category-service {
			margin: 10px;
			display: flex;
			align-items: center;
			padding: 0 15px;
			border-radius: 5px;
			opacity: 0.5;
			transition: all 0.5s;

			.service-image {
				height: 75px;
			}

			.service-name {
				margin-left: 12px;
				font-size: 24px;
				font-weight: 500;
				color: #000;
				letter-spacing: -1.5px;
			}

			&:hover {
				opacity: 1;
			}

			&.active {
				background: #fff;
				opacity: 1;
			}
		}

		.btn {
			display: none;
		}
	}

	.content-heading {
		margin-top: 50px;
		display: flex;
		justify-content: space-between;

		.heading-filters {
			display: flex;

			.input-service {
				margin-top: 0;

				input {
					height: 31.5px;
				}
			}

			.checkbox-wrapper {
				&:first-child {
					margin-top: 13px;
				}
			}

			> * {
				& + * {
					margin-left: 25px;
				}
			}
		}

		.buttons-wrapper {
			display: flex;
			align-items: center;
		}
	}
}

section.produkt-section-content {
	padding-top: 170px;

	.content-heading {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	.content-backlink {
		display: inline-block;
		// margin-bottom: 50px;

		.backlink-button {
			margin-bottom: 10px;
			display: flex;
			align-items: center;

			img, svg {
				height: 20px;
				transform: rotate(180deg);
				margin-right: 12px;
			}

			span {
				font-size: 11px;
				font-weight: 700;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: #000;
			}
		}

		.backlink-title {
			font-size: 12px;
			color: #000;
			white-space: nowrap;
		}

		.backlink-service {
			display: flex;
			align-items: center;
			transition: all 0.5s;

			.service-image {
				height: 55px;
			}

			.service-name {
				margin-left: 12px;
				font-size: 17px;
				font-weight: 600;
				color: #000;
			}
		}

		&.desktop-hidden {
			display: none;
		}
	}

	.content-navigation {
		width: calc(~'100% - (30% + 70px)');
		display: flex;
		justify-content: space-between;
		align-items: center;

		.nav-prev, .nav-next {
			display: flex;
			align-items: center;
			padding: 0;
			margin: 0;
			border: none;
			background: none;
			outline: none;

			.nav-icon {
				background-color: darken(@color2, 10%);
				border-radius: 50%;
				background-image: url(../img/icons/chevron.svg);
				background-position: center;
				background-size: 25%;
				background-repeat: no-repeat;
				display: block;
				height: 50px;
				width: 50px;
				outline: none;
				margin-right: 10px;
			}

			span {
				font-size: 11px;
				font-weight: 700;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: #000;
			}
		}

		.nav-next {
			flex-direction: row-reverse;
			margin-left: 40px;

			.nav-icon {
				transform: rotate(-180deg);
				margin-right: 0;
				margin-left: 10px;
			}
		}
	}

	.content-inner {
		margin-top: 40px;
		display: flex;
		align-items: flex-start;

		.product-gallery {
			min-width: 30%;
			max-width: 30%;

			img {
				width: 100%;
				background: #fff;
			}

			.lSSlideWrapper {
				position: relative;
			}

			.lSAction {
				a {
					margin-top: 0;
					transition: all 0.5s;
					background: none;
				}

				.lSPrev {
					width: 25px;
					height: 100%;
					left: 20px;
					top: 0;
					position: absolute;
					transform: none;

					&:after {
						content: '';
						display: block;
						width: 25px;
						height: 25px;
						background: url('../img/icons/chevron.svg') center;
						background-size: cover;
						position: absolute;
						top: 50%;
						left: 0;
						transform: translateY(-50%);
					}
				}

				.lSNext {
					width: 25px;
					height: 100%;
					right: 20px;
					top: 0;
					position: absolute;
					transform: none;

					&:after {
						content: '';
						display: block;
						width: 25px;
						height: 25px;
						background: url('../img/icons/chevron.svg') center;
						background-size: cover;
						position: absolute;
						top: 50%;
						left: 0;
						transform: translateY(-50%) rotate(180deg);
					}
				}
			}

			.lSGallery {
				margin-top: 23px !important;

				li {
					border-radius: 0;
					opacity: 0.6;
					transition: all 0.4s;

					a {
						width: 100%;
						padding-top: 100%;
						display: block;
						position: relative;

						img {
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							object-position: center;
							object-fit: cover;
						}
					}

					&.active, &:hover {
						opacity: 1;
					}
				}
			}
		}

		.product-info {
			margin-left: 70px;

			.info-title {
				font-size: 14px;
				color: #000;
				white-space: nowrap;
			}

			h1 {
				font-size: 32px;
				font-weight: 600;
				color: #000;
				white-space: nowrap;
			}

			.info-description {
				margin-top: 40px;
			}
		}

		.product-parameters {
			width: 100%;
			margin-left: 90px;

			.section-heading {
				h2 {
					font-size: 34px;
				}
			}

			.parameters-inner {
				margin-top: 50px;

				.inner-service {
					display: flex;
					justify-content: space-between;
					align-items: center;

					p {
						font-size: 18px;
						color: #000;

						&:nth-child(2) {
							font-weight: 700;
							text-align: right;
						}
					}

					& + .inner-service {
						margin-top: 21px;
						padding-top: 21px;
						border-top: 1px solid @color1;
					}
				}
			}

			.btn {
				margin-top: 50px;
			}
		}
	}

	.content-variants {
		padding-top: 150px;
		overflow: hidden;

		.products-flexbox {
			margin-top: 50px;
			justify-content: center;
			width: 105%;
			margin-left: -2.25%;

			.product-service {
				width: 20.5%;
				margin: 2.25%;
			}
		}
	}
}

section.technologia-section-content {
	padding-top: 230px;
	padding-bottom: 100px;

	.content-welcome {
		display: flex;
		justify-content: space-between;

		.welcome-image {
			width: 45%;
			display: flex;
			align-items: flex-end;

			.image-service {
				position: relative;
				overflow: hidden;

				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}

				&:nth-child(1) {
					z-index: 1;
					width: 60%;
					padding-top: 60%;
				}

				&:nth-child(2) {
					margin-left: -40%;
					width: 80%;
					padding-top: 100%;
					margin-bottom: 150px;
				}
			}
		}

		.welcome-description {
			padding: 70px 0;
			width: 45%;
		}
	}

	.content-steps {
		padding-bottom: 50px;
		margin-top: 150px;
		counter-reset: steps;

		.step-service {
			padding: 0 100px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.service-image {
				position: relative;
				width: 35%;
				padding-top: 35%;

				.image-inner {
					.inner-border {
						position: absolute;
						top: 50px;
						left: 50px;
						width: 100%;
						height: 100%;
						border: 2px solid @color1;
						transition: all 0.5s;
					}

					img {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center;
						box-shadow: 15px 10px 32px 0 rgba(0, 0, 0, 0.08);
					}
				}
			}

			.service-description {
				width: 45%;
				padding: 100px 0 100px 60px;
				position: relative;

				.number {
					position: absolute;
					top: 0;
					left: 0;
					font-size: 80px;
					color: @color1;

					&:after {
						counter-increment: steps;
						content: counter(steps) + '.';
					}
				}
			}

			& + .step-service {
				margin-top: 100px;
			}

			&:nth-child(odd) {
				flex-direction: row-reverse;
			}
		}
	}
}

section.hurt-section-content {
	padding-top: 230px;

	.content-welcome {
		display: flex;
		justify-content: space-between;

		.welcome-image {
			width: 45%;
			display: flex;
			align-items: flex-end;

			.image-service {
				position: relative;
				overflow: hidden;

				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}

				&:nth-child(1) {
					z-index: 1;
					width: 60%;
					padding-top: 60%;
				}

				&:nth-child(2) {
					margin-left: -40%;
					width: 80%;
					padding-top: 100%;
					margin-bottom: 150px;
				}
			}
		}

		.welcome-description {
			padding: 70px 0;
			width: 45%;

			p {
				max-width: 500px;
			}
		}
	}

	.products-flexbox {
		margin-top: 150px;
	}
}

section.dynamic-page-section-content {
	padding-top: 230px;

	.dynamic-text-wrapper {
		max-width: 900px;
	}
}

.content-text-photo, .content-3-photo {
	&:not(:first-child) {
		padding-top: 150px;
	}
}

.content-text-photo {
	padding: 0 125px;
	display: flex;
	justify-content: space-between;

	.text-photo-service {
		width: 41%;

		.service-image {
			img {
				width: 100%;
			}
		}

		.image-wrapper {
			position: relative;
			height: 100%;
			max-height: 800px;

			.image-border {
				position: absolute;
				top: 50px;
				left: 50px;
				width: 100%;
				height: 100%;
				border: 2px solid @color1;
				transition: all 0.5s;
			}

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}

		&.dynamic-text-wrapper {
			padding: 70px 0;
		}
	}

	& + .content-3-photo {
		padding-top: 200px;
	}
}

.content-3-photo {
	display: flex;
	justify-content: space-between;

	.photo-service {
		width: 31.5%;
		padding-top: 31.5%;
		position: relative;

		img {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			object-position: center;
			object-fit: cover;
		}
	}
}

.content-gallery {
	margin-top: 70px;

	.gallery-inner {
		margin-top: calc(~'-4% / 3');
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.inner-service {
			margin-top: calc(~'4% / 3');
			display: block;
			width: 24%;
			padding-top: 16%;
			border-radius: 3px;
			position: relative;
			overflow: hidden;
			background: #f9f9f9;

			img {
				position: absolute;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
				top: 50%;
				left: 0;
				transform: translateY(-50%;);
			}

			&.service-hidden {
				margin-top: 0 !important;
				padding-top: 0 !important;
				opacity: 0 !important;
				visibility: hidden;
			}
		}

		&.gallery-3 {
			margin-top: calc(~'-2.65% / 2');

			.inner-service {
				margin-top: calc(~'2.65% / 2');
				width: 32.45%;
				padding-top: 21.65%;
			}
		}

		&.gallery-collapsed {
			margin-top: 0;
			padding-top: 0;
		}
	}

	.btn-gallery-more {
		margin-top: 30px;

		&.hidden {
			display: none;
		}
	}
}

.content-download {
	margin-top: 90px;

	.download-inner {
		margin-top: 25px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		.inner-service {
			margin: 20px 20px 0 0;
			display: flex;
			align-items: center;
			background: #fff;
			border-radius: 3px;
			padding: 0 15px 0 0;

			.icon {
				min-width: 50px;
				max-width: 50px;
				height: 50px;
				border-radius: 3px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: @color3;
				margin-right: 15px;

				svg {
					height: 34px;
					width: 34px;
					fill: #fff;
				}
			}

			span {
				font-size: 16px;
				color: #000;
				transition: all 0.4s;
			}

			&:hover {
				span {
					color: @color3;
				}
			}
		}
	}
}

section.kontakt-section-content {
	padding-top: 230px;
	overflow: hidden;

	.content-contact {
		padding: 150px 125px 0 125px;
		display: flex;
		justify-content: space-between;

		.contact-address {
			width: 41%;

			address {
				margin-top: 50px;

				p {
					& + ul {
						margin-top: 15px;
					}
				}

				ul {
					padding-left: 0;

					li {
						a {
							display: flex;
							align-items: center;
							font-size: 16px;
							color: #000;
							text-decoration: none;
							transition: all 0.5s;

							img, svg {
								height: 20px;
								margin-right: 10px;
								fill: #000;
								transition: all 0.5s;
							}

							&:hover {
								color: @color3;
								font-weight: 700;

								svg {
									fill: @color3;
								}
							}
						}

						&:before {
							content: none;
						}

						& + li {
							margin-top: 2px;
						}
					}

					& + p {
						margin-top: 15px;
					}
				}
			}
		}

		.contact-form {
			width: 41%;

			.form-inner {
				margin-top: 35px;

				.checkbox-wrapper {
					&:first-of-type {
						margin-top: 30px;
					}
				}

				.g-recaptcha {
					margin-top: 20px;
				}

				.btn {
					margin-top: 30px;
				}
			}
		}
	}

	.content-iframe {
		padding: 150px 125px 0 125px;

		.iframe-inner {
			position: relative;
			width: 100%;
			padding-top: 50%;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100% !important;
				height: 100% !important;
				object-position: center;
				object-fit: cover;
			}
		}
	}
}

section.section-footer {
	border-top: 1px solid @color1;
	margin-top: 150px;
	padding: 120px 0;

	.footer-inner {
		display: flex;
		justify-content: space-between;

		.inner-service {
			.service-logo {
				height: 100px;
			}

			address {
				margin: 30px 0 0 0;
				font-size: 16px;
				color: #000;
			}

			.service-contact {
				margin-top: 15px;

				li {
					a {
						display: flex;
						align-items: center;

						svg, img {
							height: 20px;
							width: 20px;
							fill: #B1B4B6;
							margin-right: 6px;
						}

						span {
							transition: all 0.5s;
							color: #000;
							font-weight: 700;
							font-size: 20px;
						}

						&:hover {
							span {
								color: @color3;
							}
						}
					}

					& + li {
						margin-top: 8px;
					}
				}
			}

			.service-list {
				li {
					&:not(:last-child) {
						margin-bottom: 7px;
					}

					&.collapse {
						display: block;
					}

					&.heading {
						font-size: 17px;
						color: #000;
						font-weight: 600;
						margin-bottom: 18px;

						a {
							font-size: 17px;
							pointer-events: none;
							display: block;

							img {
								display: none;
								position: absolute;
								top: 5px;
								right: 15px;
								height: 12px;
								min-width: 12px;
								max-width: 12px;
								transition: all 0.5s;
								transform: rotate(-90deg);
							}

							&:hover {
								color: #000;
							}

							&.collapsed {
								img {
									transform: rotate(90deg);
								}
							}

							&.clickable {
								pointer-events: auto;
								padding-left: 0;
							}
						}
					}

					a {
						transition: all 0.5s;
						font-size: 14px;
						color: #000;

						&:hover {
							color: @color3;
						}

						&:not([href]) {
							&:hover {
								color: #000;
							}
						}
					}
				}

				& + .service-list {
					margin-top: 50px;
				}
			}

			.service-social-media {
				margin-top: 20px;
				display: flex;
				align-items: center;

				li {
					a {
						display: flex;
						align-items: center;

						svg, img {
							fill: #000;
							height: 24px;
							width: 24px;
							transition: all 0.5s;
						}

						&:hover {
							svg, img {
								fill: @color3;
							}
						}
					}

					& + li {
						margin-left: 7px;
					}
				}
			}
		}
	}

	&.white {
		background: #fff;
	}
}

.footer-credits {
	padding: 15px;
	background: @color3;

	p {
		font-size: 12px;
		font-weight: 500;
		color: #000;

		a {
			color: inherit;
		}
	}
}


/////////////////////// DODANE PRZEZ J.W PODSTRONA TECHNOLOGIE


.btn:focus, .btn:active:focus, .btn.active:focus{
	outline:none;
	box-shadow:none;
}

section.mozliwosci-techniczne-section-content {
	padding: 150px 0px 150px 0px;
	background-color: #fff;

	.container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.section-heading {
			text-align: center;
			text-transform: uppercase;
		}
	
		.mozliwosci-techniczne-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-flow: row wrap;
			gap: 10px;
			padding-top: 30px;

			a {
				text-transform: uppercase;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				padding: 5px 10px;
				background: #F3F2ED;
				color: #000;
			}
		}

		.kolory-zlota {
				padding-top: 150px;
				max-width: 900px;
				width: 100%;

				.kolory-heading {
					h2 {
						text-transform: none;
					}
				}

				.kolory-box {
					display: flex;
					justify-content: center;
					flex-flow: row wrap;
					text-align: center;
					padding: 45px 0px;

					.kolor-zlota {
						width: 20%;
						padding: 10px 15px 10px 15px;

						.kolor-img {
							width: 100%;
							height: 50px;
							border-radius: 50px;
							overflow: hidden;
							margin-bottom: 15px;
	
							img {
								width: 50px;
								height: 50px;
								object-fit: contain;
							}
						}
					}

					p {
						font-weight: 500;
						font-size: 22px;
						line-height: 26px;
					}
				}
		}
	}
}

section.rozmiary-section-content {
	padding: 150px 0px 0px 0px;
	background-color: #F3F2ED;;

	.container {

		.section-heading {
			text-align: center;
		}

		.rozmiar-container {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-flow: row;
			padding-top: 50px;
			gap: 60px;

			.rozmiar-kraj {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: row;
				gap: 20px;

				.rozmiar-flaga {
					width: 50px;
					height: 50px;
					overflow: hidden;

					img {
						width: 100%;
						height: auto;
						object-fit: contain;
					}
				}

				p {
					font-weight: 400;
					font-size: 30px;
					line-height: 35px;
					font-family: Arial, Helvetica, sans-serif;
				}
			}

			.rozmiar-kreska {
				width: 30px;
				height: 2px;
				background-color: #E5C5C5;
				
				&:last-child {
					display: none;
				}
			}

		}

		.rozmiar-cta {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			padding: 60px 0px 0px 0px;
			gap: 25px;
			text-align: center;

			p {
				font-weight: 500;
				font-size: 18px;
				line-height: 21px;
			}
		}
	}
}

section.proby-section-content {
	padding: 150px 0px 0px 0px;
	background-color: #F3F2ED;;

	.container {

		.section-heading {
			text-align: center;
		}

		.proby-container {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-flow: row;
			padding-top: 60px;
			gap: 80px;

			.proba {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				gap: 20px;

				.proba-img {
					width: 120px;
					height: 120px;
					overflow: hidden;
					padding-bottom: 12px;

					img {
						width: 100%;
						height: auto;
						object-fit: contain;
					}
				}

				p {
					font-weight: 500;
					font-size: 28px;
					line-height: 33px;				
					letter-spacing: 0.2em;
					font-family: Arial, Helvetica, sans-serif;
				}
			}
		}
	}
}

section.kamienie-section-content {
	padding: 150px 0px 150px 0px;
	background-color: #F3F2ED;;

	.container {

		.section-heading {
			text-align: center;
		}

		.kamienie-container {
			display: flex;
			justify-content: space-between;
			flex-flow: row;
			padding-top: 50px;
			gap: 60px;

			.kamien {
				text-align: center;
				width: 100%;

				.kamien-img {
					width: 50px;
					height: 50px;
					overflow: hidden;
					margin: 0 auto 20px auto;

					img {
						width: 100%;
						height: auto;
						object-fit: contain;
					}
				}

				p {
					text-align: center;
					font-weight: 400;
					font-size: 30px;
					line-height: 35px;
					display: inline-block;
					word-wrap: break-word;
					white-space: pre-wrap;
				}
			}

			.kamien-kreska {
				min-width: 30px;
				margin: 5px;
				height: 2px;
				background-color: #E5C5C5;
				align-self: center;

				&:last-child {
					display: none;
				}
			}	
		}
	}
}

section.oprawy-section-content {
	padding: 150px 0px 0px 0px;
	background-color: #fff;;

	.container {

		.section-heading {
			text-align: center;
		}

		.oprawy-slider-container {

			.owl-carousel {
				margin-top: 100px;

				.owl-stage-outer {
					width: 100%;
				}

				.owl-item {
					transform: scale(0.7);
					transition: all 0.3s ease-in;

					.ikona-container {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
		
						.ikona-img {
							width: 370px;
							height: 370px;
						}
	
						.nazwa {
							margin-top: 15px;
							color: #000;
							font-weight: 300;
							text-transform: uppercase;
							font-size: 11px;
						}
	
						.numer {
							color: #000;
							font-weight: 600;
							font-size: 16px;
							text-transform: uppercase;
						}
					}					

				}
			
				.owl-item.active.center {
					transform: scale(1);
					transition: all 0.3s ease-in;
				}	

				.owl-nav {
					position: absolute !important;
					margin-top: 0;
					top: 40%;
					left: 0;
					transform: translateY(-50%);
					width: 100%;
					pointer-events: none;
					justify-content: space-between;
					display: flex;
					align-items: center;
		
					.owl-prev, .owl-next {
						pointer-events: auto;
						background-color: darken(@color2, 10%);
						border-radius: 50%;
						background-image: url(../img/icons/chevron.svg);
						background-position: center;
						background-size: 25%;
						background-repeat: no-repeat;
						padding: 0;
						margin: 0;
						display: block;
						height: 50px;
						width: 50px;
						outline: none;
					}
		
					.owl-next {
						transform: rotate(-180deg);
					}
				}
	
			}			

		}
	}
}

section.grawery-section-content {
	padding: 150px 0px 0px 0px;
	background-color: #fff;;

	.container {

		.section-heading {
			text-align: center;
		}

		.grawery-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 80px 0px 0px 0px;

			.grawer {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				flex-direction: row;
				gap: 100px;
				padding: 15px;

				img {
					width: 50%;
				}

				p {
					font-weight: 500;
					font-size: 20px;
					line-height: 23px;
				}
			}
		}

		.grawery-cta {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			padding: 50px 0px 0px 0px;
			gap: 25px;
			text-align: center;

			.hidden {
				display: none;
			}
		}
	}
}

section.ikony-slider-section-content {
	padding: 150px 0px 0px 0px;
	background-color: #fff;;

	.container {

		.ikony-slider-container {

			.owl-carousel {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-content: center;
				justify-content: center;

				.owl-stage-outer {
					width: 100%;
				}

				.owl-item {
					transition: all 0.3s ease-in;
					transform: scale(0.5);

					.ikona-container {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;

						.ikona-img {
							width: 250px;
							height: 250px;
							
							img {
								width: 250px;
								height: 250px;
							}
						}
	
						.nazwa {
							margin-top: 15px;
							color: #000;
							font-weight: 300;
							text-transform: uppercase;
							font-size: 11px;
						}
	
						.numer {
							color: #000;
							font-weight: 600;
							font-size: 16px;
							text-transform: uppercase;
						}
						
					}					

				}
			
				.owl-item.active.center {
					transform: scale(1);
					transition: all 0.3s ease-in;
				}	

				.owl-nav {
					position: absolute !important;
					margin-top: 0;
					top: 40%;
					left: 0;
					transform: translateY(-50%);
					width: 100%;
					pointer-events: none;
					justify-content: space-between;
					display: flex;
					align-items: center;
		
					.owl-prev, .owl-next {
						pointer-events: auto;
						background-color: darken(@color2, 10%);
						border-radius: 50%;
						background-image: url(../img/icons/chevron.svg);
						background-position: center;
						background-size: 25%;
						background-repeat: no-repeat;
						padding: 0;
						margin: 0;
						display: block;
						height: 50px;
						width: 50px;
						outline: none;
					}
		
					.owl-next {
						transform: rotate(-180deg);
					}
				}
			}			

		}

	}
}

section.faktury-zdobienia-section-content {
	padding: 150px 0px 0px 0px;
	background-color: #fff;;

	.container {

		.section-heading {
			text-align: center;
		}

		.faktury-container {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-flow: row wrap;
			padding: 70px 0px 0px 0px;
			text-align: center;

			.faktury-img {
				width: 260px;
				height: 260px;
				overflow: hidden;

				img {
					width: 260px;
					height: auto;
					object-fit: contain;
				}
			}
		}

	}
}

section.dodatkowe-uslugi-section-content {
	padding: 150px 0px 150px 0px;
	background-color: #fff;;

	.container {

		.section-heading {
			text-align: center;
		}

		.dodatkowe-uslugi-container {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-flow: row wrap;
			padding: 70px 0px 0px 0px;
			text-align: center;
			gap: 80px;

			.dodatkowe {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				text-align: center;

				.dodatkowe-img {
					width: 50px;
					height: 50px;
					overflow: hidden;
					border-radius: 50px;
					margin-bottom: 15px;
	
					img {
						width: 100%;
						height: auto;
						object-fit: contain;
					}
				}

				p {
					font-weight: 500;
					font-size: 22px;
					line-height: 26px;
				}
			}
		}

	}
}
