
// Extralarge devices (desktops, less than 1400px)
@media (max-width: 1399.98px) {
	section.section-products {
		.products-flexbox {
			width: 350px;
		}
	}

	section.o-firmie-section-content {
		.content-management {
			.management-inner {
				padding: 0 50px;
			}
		}

		.content-abroad {
			padding: 200px 50px 0 50px;
		}

		.content-about {
			padding: 200px 50px 0 50px;
		}

		.content-csr {
			padding: 150px 50px 0 50px;
		}
	}

	section.kategoria-section-content {
		.content-heading {
			flex-direction: column;

			.heading-filters {
				display: flex;
				justify-content: space-between;

				.filters-checkboxes {
					order: -1;
				}

				> * {
					& + * {
						margin-left: 0;
					}
				}
			}

			.buttons-wrapper {
				margin-top: 30px;
				justify-content: flex-end;
			}
		}
	}

	.content-text-photo {
		padding: 0 50px;
	}

	section.kontakt-section-content {
		.content-contact {
			padding: 150px 50px 0 50px;
		}

		.content-iframe {
			padding: 150px 50px 0 50px;
		}
	}


	section.oprawy-section-content {
		background-color: #fff;;
	
		.container {
	
			.section-heading {
				text-align: center;
			}
	
			.oprawy-slider-container {
	
				.owl-carousel {
					margin-top: 100px;
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					align-content: center;
					justify-content: center;
	
					.owl-stage-outer {
						width: 100%;
					}
	
					.owl-item {
						transition: all 0.3s ease-in;

						.ikona-container {
							.ikona-img {
								width: 330px;
								height: 330px;
							}
						}	

					}
				
					.owl-item.active.center {
						transform: scale(1);
						transition: all 0.3s ease-in;
					}	
	
					.owl-nav {
						position: absolute !important;
						margin-top: 0;
						top: 40%;
						left: 0;
						transform: translateY(-50%);
						width: 100%;
						pointer-events: none;
						justify-content: space-between;
						display: flex;
						align-items: center;
			
						.owl-prev, .owl-next {
							pointer-events: auto;
							background-color: darken(@color2, 10%);
							border-radius: 50%;
							background-image: url(../img/icons/chevron.svg);
							background-position: center;
							background-size: 25%;
							background-repeat: no-repeat;
							padding: 0;
							margin: 0;
							display: block;
							height: 50px;
							width: 50px;
							outline: none;
						}
			
						.owl-next {
							transform: rotate(-180deg);
						}
					}
				}			
			}
		}
	}
		
	section.ikony-slider-section-content {
		background-color: #fff;;
	
		.container {
	
			.ikony-slider-container {
	
				.owl-carousel {
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					align-content: center;
					justify-content: center;
	
					.owl-stage-outer {
						width: 100%;
					}
	
					.owl-item {
						transition: all 0.3s ease-in;
						transform: scale(0.5);
					}
				
					.owl-item.active.center {
						transform: scale(1);
						transition: all 0.3s ease-in;
					}	
	
					.owl-nav {
						position: absolute !important;
						margin-top: 0;
						top: 40%;
						left: 0;
						transform: translateY(-50%);
						width: 100%;
						pointer-events: none;
						justify-content: space-between;
						display: flex;
						align-items: center;
			
						.owl-prev, .owl-next {
							pointer-events: auto;
							background-color: darken(@color2, 10%);
							border-radius: 50%;
							background-image: url(../img/icons/chevron.svg);
							background-position: center;
							background-size: 25%;
							background-repeat: no-repeat;
							padding: 0;
							margin: 0;
							display: block;
							height: 50px;
							width: 50px;
							outline: none;
						}
			
						.owl-next {
							transform: rotate(-180deg);
						}
					}
		
					.ikona-container {
		
						.ikona-img {
							width: 250px;
							height: 250px;
						}
					}
				}			
	
			}
	
		}
	}
	
}


// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
	nav.section-header {
		.header-flexbox {
			.flexbox-inner {
				> * {
					& + * {
						margin-left: 40px;
					}
				}

				.flexbox-nav {
					li {
						& + li {
							margin-left: 30px;
						}
					}
				}

				.flexbox-social-media {
					display: flex;
					align-items: center;

					li {
						a {
							display: flex;
							align-items: center;

							svg, img {
								fill: #000;
								height: 24px;
								width: 24px;
								transition: all 0.5s;
							}
						}

						& + li {
							margin-left: 7px;
						}
					}
				}
			}
		}
	}

	section.section-offer {
		.offer-inner {
			.inner-service {
				.service-inner {
					.inner-name {
						font-size: 40px;
					}
				}

				&:nth-child(1) {
					.service-inner {
						width: 420px;
					}
				}

				&:nth-child(2) {
					.service-inner {
						width: 330px;
					}
				}
			}
		}
	}

	section.section-products {
		.owl-carousel {
			.owl-nav {
				.owl-prev, .owl-next {
					height: 40px;
					width: 40px;
				}
			}
		}

		.products-flexbox {
			width: 293px;
		}
	}

	section.o-firmie-section-content {
		.content-management {
			.management-inner {
				padding: 0 50px 0 0;
			}
		}

		.content-abroad {
			padding: 200px 0 0 0;
		}

		.content-about {
			padding: 200px 0 0 0;
		}

		.content-csr {
			padding: 150px 50px 0 0;
		}
	}

	.content-text-photo {
		padding: 0;

		.text-photo-service {
			&:last-child {
				&:not(.dynamic-text-wrapper) {
					padding-right: 50px;
				}
			}
		}
	}

	section.kontakt-section-content {
		.content-contact {
			padding: 150px 0 0 0;
		}

		.content-iframe {
			padding: 150px 0 0 0;
		}
	}

/////////////////////// DODANE PRZEZ J.W PODSTRONA TECHNOLOGIE

section.oprawy-section-content {

	.container {

		.section-heading {
			text-align: center;
		}

		.oprawy-slider-container {

			.owl-carousel {
				margin-top: 100px;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-content: center;
				justify-content: center;

				.owl-stage-outer {
					width: 100%;
				}

				.owl-item {
					transition: all 0.3s ease-in;
				}
			
				.owl-item.active.center {
					transform: scale(1);
					transition: all 0.3s ease-in;
				}	

				.owl-nav {
					position: absolute !important;
					margin-top: 0;
					top: 40%;
					left: 0;
					transform: translateY(-50%);
					width: 100%;
					pointer-events: none;
					justify-content: space-between;
					display: flex;
					align-items: center;
		
					.owl-prev, .owl-next {
						pointer-events: auto;
						background-color: darken(@color2, 10%);
						border-radius: 50%;
						background-image: url(../img/icons/chevron.svg);
						background-position: center;
						background-size: 25%;
						background-repeat: no-repeat;
						padding: 0;
						margin: 0;
						display: block;
						height: 50px;
						width: 50px;
						outline: none;
					}
		
					.owl-next {
						transform: rotate(-180deg);
					}
				}
			}			
		}
	}
}
	
section.ikony-slider-section-content {

	.container {

		.ikony-slider-container {

			.owl-carousel {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-content: center;
				justify-content: center;

				.owl-stage-outer {
					width: 100%;
				}

				.owl-item {
					transition: all 0.3s ease-in;
				}
			
				.owl-item.active.center {
					transform: scale(1);
					transition: all 0.3s ease-in;
				}	

				.owl-nav {
					position: absolute !important;
					margin-top: 0;
					top: 40%;
					left: 0;
					transform: translateY(-50%);
					width: 100%;
					pointer-events: none;
					justify-content: space-between;
					display: flex;
					align-items: center;
		
					.owl-prev, .owl-next {
						pointer-events: auto;
						background-color: darken(@color2, 10%);
						border-radius: 50%;
						background-image: url(../img/icons/chevron.svg);
						background-position: center;
						background-size: 25%;
						background-repeat: no-repeat;
						padding: 0;
						margin: 0;
						display: block;
						height: 50px;
						width: 50px;
						outline: none;
					}
		
					.owl-next {
						transform: rotate(-180deg);
					}
				}
	
				.ikona-container {
	
					.ikona-img {
						width: 250px;
						height: 250px;
					}
				}
			}			

		}

	}
}


section.faktury-zdobienia-section-content {

	.container {

		.faktury-container {
			justify-content: center;

			.faktury-img {
				width: 220px;
				height: 220px;

				img {
					width: 220px;
				}
			}
		}

	}
}
			
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
	.header-sidebar {
		display: block;
	}

	nav.section-header {
		.header-flexbox {
			.flexbox-inner {
				> * {
					& + * {
						margin-left: 30px;
					}
				}

				.flexbox-nav {
					&:not(:last-of-type) {
						display: none;
					} 
				}
			}

			.nav-button {
				display: flex;
			}
		}

		&.white {
			.header-flexbox {
				.flexbox-logo {
					img, svg {
						fill: #000;
					}
				}

				.flexbox-inner {
					.flexbox-nav {
						li {
							> a {
								color: #000;
							}

							.dropdown {
								> a, button {
									svg {
										fill: #000;
									}

									span {
										color: #000;
									}
								}
							}
						}
					}

					.flexbox-social-media {
						li {
							a {
								svg, img {
									fill: #000;
								}
							}
						}
					}

					.nav-button {
						.button-bar {
							background: #000;
						}
					}
				}
			}

			&.scrolled {
				background: rgba(255, 255, 255, 0.5);

				&:hover {
					background: rgba(255, 255, 255, 0.75);
				}
			}
		}
	}

	section.section-welcome {
		padding-top: 130px;

		.welcome-item {
			.item-description {
				.description-text {
					min-height: calc(~'100vh - 130px');
				}
			}
		}
	}

	section.section-offer {
		.offer-inner {
			.inner-service {
				.service-inner {
					.inner-name {
						font-size: 30px;
					}
				}

				&:nth-child(1) {
					.service-inner {
						width: 320px;
					}
				}

				&:nth-child(2) {
					.service-inner {
						width: 230px;
					}
				}
			}
		}
	}

	section.section-about {
		.about-inner {
			.inner-image {
				min-width: 270px;
				max-width: 270px;
				margin-right: 50px;
			}
		}
	}

	section.section-products {
		.owl-carousel {
			.owl-nav {
				display: none;
			}
		}

		.container {
			padding: 0;
			max-width: 100%;
		}
	}

	section.section-partnership {
		.partnership-inner {
			.inner-image {
				min-width: 240px;
				max-width: 240px;
			}
		}
	}

	section.o-firmie-section-content {
		.content-history {
			.tab-content {
				.content-inner {
					display: flex;
					align-items: center;

					.inner-description {
						margin-right: 50px;
					}

					.inner-image {
						min-width: 200px;
						max-width: 200px;
					}
				}
			}
		}

		.content-management {
			& + .content-text-photo {
				margin-top: 50px;
			}
		}

		.content-abroad {
			padding: 150px 0 0 0;
		}

		.content-about {
			padding: 150px 0 0 0;
		}
	}

	section.kategoria-section-content {
		.content-categories {
			.category-service {
				.service-image {
					height: 60px;
				}

				.service-name {
					font-size: 19px;
				}
			}
		}

		.content-heading {
			.heading-filters {
				flex-wrap: wrap;

				.filters-checkboxes {
					order: 100;
					width: 100%;
					display: flex;
					margin-top: 40px;
				}

				.checkbox-wrapper {
					margin: auto 0 0 0;

					&:first-child {
						margin-top: auto;
					}

					& + .checkbox-wrapper {
						margin-left: 20px;
					}
				}
			}

			.buttons-wrapper {
				margin-top: -27px;
			}
		}

		.products-flexbox {
			margin-top: 40px;
		}
	}

	section.produkt-section-content {
		.content-heading {
			flex-direction: column;
			align-items: flex-start;
		}

		.content-navigation {
			margin-top: 40px;
			width: 100%;
		}

		.content-inner {
			flex-wrap: wrap;

			.product-gallery {
				min-width: 50%;
				max-width: 50%;
			}

			.product-info {
				margin-left: 60px;
			}

			.product-parameters {
				margin-top: 100px;
				margin-left: 0;
			}
		}
	}

	section.technologia-section-content {
		.content-welcome {
			align-items: center;

			.welcome-description {
				padding: 0;
			}
		}

		.content-steps {
			margin-top: 120px;

			.step-service {
				padding: 0;

				& + .step-service {
					margin-top: 70px;
				}
			}
		}
	}

	section.hurt-section-content {
		.content-welcome {
			align-items: center;

			.welcome-description {
				padding: 0;
			}
		}
	}

	section.section-footer {
		.footer-inner {
			flex-wrap: wrap;

			.inner-service {
				&:nth-child(1) {
					margin-bottom: 60px;
					width: 100%;
					text-align: center;
				}
			}
		}
	}

/////////////////////// DODANE PRZEZ J.W PODSTRONA TECHNOLOGIE

section.mozliwosci-techniczne-section-content {
	padding: 100px 0px 00px 0px;

	.container {
		flex-direction: column;
	
		.kolory-zlota {
				padding-top: 100px;
		}
	}
}

section.rozmiary-section-content {
	padding: 100px 0px 0px 0px;

	.container {

		.rozmiar-container {
			flex-flow: row;
			gap: 20px;

			.rozmiar-kraj {
				p{
					font-size: 20px;
				}
			}

			.rozmiar-kreska {
				width: 10px;
			}
		}

		.rozmiar-cta {
			flex-direction: column;
		}
	}
}

section.proby-section-content {
	padding: 100px 0px 0px 0px;
	background-color: #F3F2ED;;

	.container {

		.proby-container {
			flex-flow: row wrap;
			}
	}	
}

section.kamienie-section-content {
	padding: 100px 0px 100px 0px;

	.container {

		.kamienie-container {
			justify-content: space-around;
			flex-flow: row wrap;
			gap: 10px;
			
			.kamien {
				width: auto;
				.kamien-img {
					width: 50px;
					height: 50px;
					margin: 0 auto 20px auto;
				}

				p {
					font-size: 24px;
				}
			}

			.kamien-kreska {
				width: 0;
			}
		}
	}
}

section.oprawy-section-content {
	padding: 100px 0px 0px 0px;
	background-color: #fff;;

	.container {

		.oprawy-slider-container {

			.owl-carousel {
				margin-top: 100px;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-content: center;
				justify-content: center;

				.owl-stage-outer {
					width: 100%;
				}

				.owl-item {
					transition: all 0.3s ease-in;

					.ikona-container {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
		
						.ikona-img {
							width: 220px;
							height: 220px;
						}
					}	
				}
			
				.owl-item.active.center {
					transform: scale(1);
					transition: all 0.3s ease-in;
				}	

				.owl-nav {
					position: absolute !important;
					margin-top: 0;
					top: 40%;
					left: 0;
					transform: translateY(-50%);
					width: 100%;
					pointer-events: none;
					justify-content: space-between;
					display: flex;
					align-items: center;
		
					.owl-prev, .owl-next {
						pointer-events: auto;
						background-color: darken(@color2, 10%);
						border-radius: 50%;
						background-image: url(../img/icons/chevron.svg);
						background-position: center;
						background-size: 25%;
						background-repeat: no-repeat;
						padding: 0;
						margin: 0;
						display: block;
						height: 50px;
						width: 50px;
						outline: none;
					}
		
					.owl-next {
						transform: rotate(-180deg);
					}
				}
			}			
		}
	}
}

section.grawery-section-content {
	padding: 100px 0px 0px 0px;
	background-color: #fff;;

	.container {

		.grawery-container {
			flex-direction: column;

			.grawer {
				flex-direction: column;
				gap: 20px;
				margin-bottom: 20px;

				img {
					width: 100%;
				}
			}
		}
	}
}

section.ikony-slider-section-content {
	padding: 100px 0px 0px 0px;

	.container {

		.ikony-slider-container {

			.owl-carousel {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-content: center;
				justify-content: center;

				.owl-stage-outer {
					width: 100%;
				}

				.owl-item {
					transition: all 0.3s ease-in;
					transform: scale(0.5);
				}
			
				.owl-item.active.center {
					transform: scale(1);
					transition: all 0.3s ease-in;
				}	

				.owl-nav {
					position: absolute !important;
					margin-top: 0;
					top: 40%;
					left: 0;
					transform: translateY(-50%);
					width: 100%;
					pointer-events: none;
					justify-content: space-between;
					display: flex;
					align-items: center;
		
					.owl-prev, .owl-next {
						pointer-events: auto;
						background-color: darken(@color2, 10%);
						border-radius: 50%;
						background-image: url(../img/icons/chevron.svg);
						background-position: center;
						background-size: 25%;
						background-repeat: no-repeat;
						padding: 0;
						margin: 0;
						display: block;
						height: 50px;
						width: 50px;
						outline: none;
					}
		
					.owl-next {
						transform: rotate(-180deg);
					}
				}
	
				.ikona-container {
	
					.ikona-img {
						width: 250px;
						height: 250px;
					}
				}
			}			

		}

	}
}

section.faktury-zdobienia-section-content {
	padding: 100px 0px 0px 0px;
	background-color: #fff;;

	.container {

		.faktury-container {
			justify-content: center;

			.faktury-img {
				width: 150px;
				height: 150px;
				overflow: hidden;
				margin: 5px;

				img {
					width: 150px;
					height: auto;
					object-fit: contain;
				}
			}
		}

	}
}

section.dodatkowe-uslugi-section-content {
	padding: 100px 0px 100px 0px;
	background-color: #fff;;

	.container {

		.dodatkowe-uslugi-container {

			.dodatkowe {
				flex-direction: column;
			}
		}

	}
}
		
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
	.products-flexbox {
		.product-service {
			margin-top: 4%;
			width: 31%;
		}

		&.small-margin {
			.product-service {
				margin-top: 3.5%;
			}
		}
	}

	section.section-welcome {
		.welcome-item {
			text-align: center;

			.item-description {
				.description-text {
					padding: 50px 0;
					align-items: center;
				}
			}
		}
	}

	section.section-offer {
		.offer-inner {
			flex-direction: column;

			.inner-service {
				.service-inner {
					.inner-name {
						font-size: 30px;
					}
				}

				&:nth-child(1) {
					.service-inner {
						width: 320px;
						max-width: 100%;
					}
				}

				&:nth-child(2) {
					margin-top: 100px;

					.service-inner {
						width: 320px;
						max-width: 100%;
					}
				}
			}
		}
	}

	section.section-wholesale {
		.wholesale-inner {
			justify-content: center;

			.inner-description {
				text-align: center;
				white-space: normal;
				margin-right: 0;
			}

			.inner-gallery {
				display: none;
			}
		}
	}

	section.section-about {
		.about-inner {
			flex-direction: column;

			.inner-image {
				min-width: auto;
				width: 280px;
				max-width: 100%;
				margin-right: 0;
			}

			.inner-description {
				margin-top: 50px;
				text-align: center;
			}
		}

		.about-advantages {
			margin-top: 50px;
			flex-wrap: wrap;

			&.small-margin {
				margin-top: 50px;
			}

			.advantage-service {
				margin-top: 50px;
				width: 50%;
				text-align: center;
			}
		}
	}

	section.section-partnership {
		.partnership-inner {
			flex-direction: column-reverse;

			.inner-image {
				min-width: auto;
				width: 220px;
				max-width: 100%;
				margin-left: 0;
			}

			.inner-description {
				margin-top: 50px;
				text-align: center;
			}
		}
	}

	section.o-firmie-section-content {
		.content-history {
			.tab-content {
				.content-inner {
					flex-direction: column;

					.inner-description {
						margin-right: 0;
						text-align: center;
					}

					.inner-image {
						margin-top: 50px;
						min-width: auto;
						width: 400px;
						max-width: 100%;
					}
				}
			}
		}

		.content-management {
			.management-inner {
				flex-direction: column;
				align-items: center;
				padding-right: 0;

				.inner-service {
					width: 100%;
					max-width: 280px;

					& + .inner-service {
						margin-top: 70px;
					}
				}
			}

			& + .content-text-photo {
				margin-top: 0;
			}
		}

		.content-abroad {
			display: flex;
			flex-direction: column-reverse;

			img {
				margin-top: 0;
				margin-bottom: 50px;
			}
		}

		.content-about {
			.about-advantages {
				flex-wrap: wrap;

				.advantage-service {
					margin-top: 50px;
					width: 50%;
					text-align: center;
				}
			}
		}

		.content-csr {
			padding: 120px 0 0 0;

			.csr-inner {
				margin-top: 70px;
				flex-direction: column;
				align-items: center;

				.inner-service {
					width: 100%;
					max-width: 280px;
					text-align: center;

					&:nth-child(2) {
						margin-top: 70px;
					}
				}
			}
		}

		.content-cta {
			margin-top: 120px;
			padding: 120px 0;

			.btn {
				margin-top: 60px;
			}
		}
	}

	section.kategoria-section-content {
		.content-categories {
			justify-content: space-between;

			.category-service {
				margin: 0;
				padding: 0;
				pointer-events: none;

				&:not(.active) {
					display: none;
				}

				.service-image {
					height: 60px;
				}

				.service-name {
					font-size: 19px;
				}

				&.active {
					background: none;
				}
			}

			.btn {
				display: block;
			}
		}

		.content-heading {
			display: none;
		}

		.products-flexbox {
			margin-top: 40px;
		}
	}

	section.produkt-section-content {
		.content-inner {
			.product-info {
				.btn {
					padding: 7px 35px;
					font-size: 12px;
				}
			}
		}

		.content-variants {
			.products-flexbox {
				width: 107%;
				margin-left: -3.5%;

				.product-service {
					width: 43%;
					margin: 3.5%;
				}
			}
		}
	}

	section.technologia-section-content {
		padding-top: 170px;

		.content-welcome {
			flex-direction: column;

			.welcome-image {
				width: 100%;

				.image-service {
					&:nth-child(1) {
						width: 100%;
						padding-top: 50%;
					}

					&:nth-child(2) {
						display: none;
					}
				}
			}

			.welcome-description {
				margin-top: 50px;
				text-align: center;
				width: 100%;
			}
		}

		.content-steps {
			margin-top: 120px;
			padding-right: 50px;

			.step-service {
				flex-direction: column-reverse;

				.service-image {
					margin-top: 40px;
					width: 300px;
					padding-top: 300px;
				}

				.service-description {
					width: 100%;
					padding: 60px 0 0 55px;

					.number {
						line-height: 50px;
					}
				}

				& + .step-service {
					margin-top: 100px;
				}

				&:nth-child(odd) {
					flex-direction: column-reverse;
				}
			}
		}
	}

	section.hurt-section-content {
		padding-top: 170px;

		.content-welcome {
			flex-direction: column;

			.welcome-image {
				width: 100%;
				margin-top: 50px;

				.image-service {
					&:nth-child(1) {
						width: 100%;
						padding-top: 50%;
					}

					&:nth-child(2) {
						display: none;
					}
				}
			}

			.welcome-description {
				text-align: center;
				width: 100%;
			}
		}

		.products-flexbox {
			margin-top: 120px;
		}
	}

	section.dynamic-page-section-content {
		padding-top: 190px;
	}

	.content-text-photo {
		flex-direction: column;

		.text-photo-service {
			width: 100%;

			.service-image {
				max-width: 450px;
				margin: 0 auto;
			}

			.image-wrapper {
				position: relative;
				height: auto;
				max-height: 100%;
				margin: 0 auto;
				padding-right: 50px;

				img {
					position: relative;
					top: auto;
					left: auto;
					width: 100%;
					height: auto;
				}

				.image-border {
					left: auto;
					right: 0;
					width: calc(~'100% - 50px')
				}
			}

			& + .text-photo-service {
				margin-top: 50px;
			}

			&.dynamic-text-wrapper {
				padding: 0;
				text-align: center;
			}

			&:last-child {
				&:not(.dynamic-text-wrapper) {
					padding-right: 0;
				}
			}
		}

		&.mobile-reversed {
			flex-direction: column-reverse;

			.text-photo-service {
				& + .text-photo-service {
					margin-top: 0;
					margin-bottom: 50px;
				}
			}
		}
	}

	.content-gallery {
		.gallery-inner {
			margin-top: 4%;

			.inner-service {
				margin-top: 4%;
				width: 48%;
				padding-top: 32%;
			}
		}
	}

	.content-download {
		margin-top: 70px;

		.download-inner {
			.inner-service {
				margin: 12px 12px 0 0;
				padding: 0 12px 0 0;

				.icon {
					min-width: 32px;
					max-width: 32px;
					height: 32px;
					margin-right: 9px;

					svg {
						height: 26px;
						width: 26px;
					}
				}

				span {
					font-size: 14px;
				}
			}
		}
	}

	section.kontakt-section-content {
		padding-top: 190px;

		.content-contact {
			padding: 120px 0 0 0;
			flex-direction: column;

			.contact-address {
				width: 100%;
				text-align: center;

				address {
					ul {
						li {
							a {
								justify-content: center;
							}
						}
					}
				}
			}

			.contact-form {
				margin-top: 70px;
				width: 100%;
				text-align: center;

				label, input {
					text-align: center;
				}
			}
		}

		.content-iframe {
			padding: 120px 0 0 0;
		}
	}

/////////////////////// DODANE PRZEZ J.W PODSTRONA TECHNOLOGIE

section.mozliwosci-techniczne-section-content {
	padding: 100px 0px 00px 0px;

	.container {
		flex-direction: column;
	
		.kolory-zlota {
				padding-top: 100px;

				.kolory-box {
				}
		}
	}
}

section.rozmiary-section-content {
	padding: 100px 0px 0px 0px;

	.container {

		.rozmiar-container {
			flex-flow: row;
			gap: 20px;

			.rozmiar-kraj {
				p{
					font-size: 20px;
				}
			}

			.rozmiar-kreska {
				width: 10px;
			}
		}

		.rozmiar-cta {
			flex-direction: column;
		}
	}
}

section.proby-section-content {
	padding: 100px 0px 0px 0px;
	background-color: #F3F2ED;;

	.container {

		.proby-container {
			flex-flow: row wrap;
			}
	}	
}

section.kamienie-section-content {
	padding: 100px 0px 100px 0px;

	.container {

		.kamienie-container {
			justify-content: space-around;
			flex-flow: row wrap;
			gap: 10px;
			
			.kamien {
				width: auto;
				.kamien-img {
					margin: 0 auto 20px auto;
					width: 50px;
					height: 50px;
				}

				p {
					font-size: 24px;
				}
			}

			.kamien-kreska {
				width: 0;
			}
		}
	}
}

section.oprawy-section-content {
	padding: 100px 0px 0px 0px;
	background-color: #fff;;

	.container {

		.oprawy-slider-container {

			.owl-carousel {
				margin-top: 100px;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-content: center;
				justify-content: center;

				.owl-stage-outer {
					width: 100%;
				}

				.owl-item {
					transition: all 0.3s ease-in;

					.ikona-container {
						.ikona-img {
							width: 350px;
							height: 350px;
						}
					}						
				}
			
				.owl-item.active.center {
					transform: scale(1);
					transition: all 0.3s ease-in;
				}	

				.owl-nav {
					position: absolute !important;
					margin-top: 0;
					top: 40%;
					left: 0;
					transform: translateY(-50%);
					width: 100%;
					pointer-events: none;
					justify-content: space-between;
					display: flex;
					align-items: center;
		
					.owl-prev, .owl-next {
						pointer-events: auto;
						background-color: darken(@color2, 10%);
						border-radius: 50%;
						background-image: url(../img/icons/chevron.svg);
						background-position: center;
						background-size: 25%;
						background-repeat: no-repeat;
						padding: 0;
						margin: 0;
						display: block;
						height: 50px;
						width: 50px;
						outline: none;
					}
		
					.owl-next {
						transform: rotate(-180deg);
					}
				}
			}			
		}
	}
}

section.grawery-section-content {
	padding: 100px 0px 0px 0px;
	background-color: #fff;;

	.container {

		.grawery-container {
			flex-direction: column;

			.grawer {
				flex-direction: column;
				gap: 20px;
				margin-bottom: 20px;

				img {
					width: 100%;
				}
			}
		}
	}
}

section.ikony-slider-section-content {
	padding: 100px 0px 0px 0px;

	.container {

		.ikony-slider-container {

			.owl-carousel {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-content: center;
				justify-content: center;

				.owl-stage-outer {
					width: 100%;
				}

				.owl-item {
					transition: all 0.3s ease-in;
				}
			
				.owl-item.active.center {
					transform: scale(1);
					transition: all 0.3s ease-in;
				}	

				.owl-nav {
					position: absolute !important;
					margin-top: 0;
					top: 40%;
					left: 0;
					transform: translateY(-50%);
					width: 100%;
					pointer-events: none;
					justify-content: space-between;
					display: flex;
					align-items: center;
		
					.owl-prev, .owl-next {
						pointer-events: auto;
						background-color: darken(@color2, 10%);
						border-radius: 50%;
						background-image: url(../img/icons/chevron.svg);
						background-position: center;
						background-size: 25%;
						background-repeat: no-repeat;
						padding: 0;
						margin: 0;
						display: block;
						height: 50px;
						width: 50px;
						outline: none;
					}
		
					.owl-next {
						transform: rotate(-180deg);
					}
				}
	
				.ikona-container {
	
					.ikona-img {
						width: 250px;
						height: 250px;
					}
				}
			}			

		}

	}
}

section.faktury-zdobienia-section-content {
	padding: 100px 0px 0px 0px;
	background-color: #fff;;

	.container {

		.faktury-container {
			justify-content: center;

			.faktury-img {
				width: 150px;
				height: 150px;
				overflow: hidden;
				margin: 5px;

				img {
					width: 150px;
					height: auto;
					object-fit: contain;
				}
			}
		}

	}
}

section.dodatkowe-uslugi-section-content {
	padding: 100px 0px 100px 0px;
	background-color: #fff;;

	.container {

		.dodatkowe-uslugi-container {

			.dodatkowe {
				flex-direction: column;
			}
		}

	}
}

}

@media (max-width: 650px) {
	body {
		.btn {
			padding: 8px 32px;
			font-size: 15px;

			&.btn-small {
				padding: 6px 29px;
				font-size: 11px;
			}
		}
	}

	.dynamic-text-wrapper {
		h1 {
			font-size: 38px;
		}

		h2 {
			font-size: 38px;
		}

		h3 {
			font-size: 30px;
		}

		h4 {
			font-size: 26px;
		}

		h5 {
			font-size: 21px;
		}

		h6 {
			font-size: 18px;
		}

		p, li {
			font-size: 15px;
		}

		table {
			tr {
				td {
					font-size: 15px;
				}
			}
		}

		.accordion {
			.accordion-item {
				.accordion-header {
					.accordion-button {
						font-size: 20px;
					}
				}
			}
		}
	}

	section.section-welcome {
		.welcome-item {
			.item-description {
				.description-text {
					h1, h2 {
						font-size: 38px;

						b, strong {
							font-size: 50px;
						}
					}

					.btn {
						margin-top: 100px;
					}
				}
			}
		}
	}

	.section-heading {
		h1, h2, .heading-inner {
			font-size: 38px;
			letter-spacing: -1.5px;
		}

		p {
			font-size: 17px;
		}

		.btn {
			margin-top: 40px;
		}
	}

	section.section-offer {
		padding-top: 70px;

		.offer-inner {
			margin-top: 70px;
			padding-right: 20px;

			.inner-service {
				.service-inner {
					.inner-border {
						position: absolute;
						top: 20px;
						left: 20px;
					}

					&:hover {
						transform: translate(10px, 10px);

						.inner-border {
							transform: translate(-30px, -30px);
							height: calc(~'100% + 20px');
							width: calc(~'100% + 20px');
						}
					}
				}
			}
		}
	}

	section.section-about {
		&.subpage {
			padding-top: 100px;
		}

		.about-advantages {
			&.small-margin {
				margin-top: 0;
			}
		}
	}

	section.section-slogan {
		padding-top: 100px;
	}

	section.section-wholesale {
		padding-top: 100px;
	}

	section.section-products {
		margin-top: 70px;
		padding: 70px 0;

		.owl-carousel {
			margin-top: 50px;
		}

		.products-flexbox {
			width: 245px;
		}
	}

	section.section-partnership {
		padding-top: 70px;
	}

	section.o-firmie-section-content {
		padding-top: 190px;

		.content-history {
			padding-top: 70px;

			.nav-tabs {
				margin-top: 40px;
			}

			.tab-content {
				margin-top: 40px;
			}
		}

		.content-management {
			padding-top: 70px;

			.management-inner {
				margin-top: 50px;

				.inner-service {
					.service-image {
						.image-border {
							top: 20px;
							left: 20px;
						}
					}

					.name {
						margin-top: 40px;
					}

					& + .inner-service {
						margin-top: 50px;
					}
				}
			}
		}

		.content-abroad {
			padding: 100px 0 0 0;
		}

		.content-about {
			padding: 100px 0 0 0;
		}

		.content-csr {
			padding: 100px 0 0 0;

			.csr-inner {
				.inner-service {
					.service-image {
						margin-bottom: 40px;

						.image-border {
							top: 20px;
							left: 20px;
						}
					}
				}
			}
		}

		.content-cta {
			margin-top: 100px;
			padding: 100px 0;

			.btn {
				margin-top: 40px;
			}
		}
	}

	section.produkt-section-content {
		padding-top: 150px;

		.content-heading {
			max-width: 420px;
			margin: 0 auto 30px auto;
		}

		.content-backlink {
			margin-bottom: 30px;

			&.desktop-hidden {
				display: block;
			}
		}

		.content-navigation {
			max-width: 420px;
			margin: 0 auto;
		}

		.content-inner {
			flex-direction: column;
			align-items: center;
			max-width: 420px;
			margin: 0 auto;

			.product-gallery {
				min-width: 100%;
				max-width: 100%;
			}

			.product-info {
				margin-top: 50px;
				margin-left: 0;
				text-align: center;

				.content-backlink {
					display: none;
				}

				.btn {
					margin-top: 40px;
				}
			}

			.product-parameters {
				margin-top: 70px;

				.parameters-inner {
					margin-top: 70px;

					.inner-service {
						p {
							font-size: 16px;
						}

						& + .inner-service {
							margin-top: 30px;
							padding-top: 30px;
						}
					}
				}
			}
		}

		.content-variants {
			padding-top: 100px;
		}
	}

	section.technologia-section-content {
		padding-top: 170px;

		.content-steps {
			margin-top: 90px;
			padding-right: 20px;

			.step-service {
				.service-image {
					.image-inner {
						.inner-border {
							top: 20px;
							left: 20px;
						}
					}
				}

				& + .step-service {
					margin-top: 80px;
				}
			}
		}
	}

	section.hurt-section-content {
		padding-top: 170px;

		.products-flexbox {
			margin-top: 80px;
		}
	}

	.content-text-photo, .content-3-photo {
		&:not(:first-child) {
			padding-top: 90px;
		}
	}

	.content-text-photo {
		.text-photo-service {
			.image-wrapper {
				padding-right: 20px;

				.image-border {
					width: calc(~'100% - 20px')
				}
			}
		}


		& + .content-3-photo {
			padding-top: 120px;
		}
	}

	section.section-footer {
		margin-top: 70px;
		padding: 0 0 50px 0;

		.container {
			padding: 0;
		}

		.footer-inner {
			.inner-service {
				width: 100%;

				&:nth-child(1) {
					display: none;
				}

				&:last-child {
					padding: 0 20px;

					ul {
						ul {
							padding-left: 0;
						}
					}
				}

				.service-list {
					li {
						&:not(:last-child) {
							margin-bottom: 4px;
						}

						&.heading {
							font-size: 20px;
							margin-bottom: 10px;

							a {
								font-size: 18px;
								font-weight: 500;
								position: relative;
								padding: 0 20px;
								pointer-events: auto;

								img {
									display: block;
								}
							}
						}

						&.collapse {
							display: none;

							&.show {
								display: block;
							}
						}

						a {
							font-size: 14px;
						}
					}

					ul {
						padding: 0 20px;

						li {
							& + li {
								margin-top: 10px;
							}
						}
					}

					& + .service-list {
						border-top: 1px solid @color1;
						margin-top: 15px;
						padding-top: 15px;
					}

					&:last-child {
						margin-bottom: 15px;
					}
				}

				& + .inner-service {
					border-top: 1px solid @color1;
					margin-top: 0;
					padding-top: 15px;
				}
			}
		}
	}

/////////////////////// DODANE PRZEZ J.W PODSTRONA TECHNOLOGIE

section.mozliwosci-techniczne-section-content {
	padding: 100px 0px 00px 0px;

	.container {
		flex-direction: column;
	
		.kolory-zlota {
				padding-top: 100px;
		}
	}
}

section.rozmiary-section-content {
	padding: 100px 0px 0px 0px;

	.container {

		.rozmiar-container {
			flex-flow: row;
			gap: 20px;

			.rozmiar-kraj {
				p{
					font-size: 20px;
				}
			}

			.rozmiar-kreska {
				width: 10px;
			}
		}

		.rozmiar-cta {
			flex-direction: column;
		}
	}
}

section.proby-section-content {
	padding: 100px 0px 0px 0px;
	background-color: #F3F2ED;;

	.container {

		.proby-container {
			flex-flow: row wrap;
			}
	}	
}

section.kamienie-section-content {
	padding: 100px 0px 100px 0px;

	.container {

		.kamienie-container {
			justify-content: space-around;
			flex-flow: row wrap;
			gap: 10px;
			
			.kamien {
				width: auto;
				.kamien-img {
					width: 50px;
					height: 50px;
					margin: 0 auto 20px auto;
				}

				p {
					font-size: 24px;
				}
			}

			.kamien-kreska {
				width: 0;
			}
		}
	}
}

section.oprawy-section-content {
	padding: 100px 0px 0px 0px;
	background-color: #fff;;

	.container {

		.oprawy-slider-container {

			.owl-carousel {
				margin-top: 100px;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-content: center;
				justify-content: center;

				.owl-stage-outer {
					width: 100%;
				}

				.owl-item {
					transition: all 0.3s ease-in;
				}
			
				.owl-item.active.center {
					transform: scale(1);
					transition: all 0.3s ease-in;
				}	

				.owl-nav {
					position: absolute !important;
					margin-top: 0;
					top: 40%;
					left: 0;
					transform: translateY(-50%);
					width: 100%;
					pointer-events: none;
					justify-content: space-between;
					display: flex;
					align-items: center;
		
					.owl-prev, .owl-next {
						pointer-events: auto;
						background-color: darken(@color2, 10%);
						border-radius: 50%;
						background-image: url(../img/icons/chevron.svg);
						background-position: center;
						background-size: 25%;
						background-repeat: no-repeat;
						padding: 0;
						margin: 0;
						display: block;
						height: 50px;
						width: 50px;
						outline: none;
					}
		
					.owl-next {
						transform: rotate(-180deg);
					}
				}
			}			
		}
	}
}

section.grawery-section-content {
	padding: 100px 0px 0px 0px;
	background-color: #fff;;

	.container {

		.grawery-container {
			flex-direction: column;

			.grawer {
				flex-direction: column;
				gap: 20px;
				margin-bottom: 20px;

				img {
					width: 100%;
				}
			}
		}
	}
}

section.ikony-slider-section-content {
	padding: 100px 0px 0px 0px;

	.container {

		.ikony-slider-container {

			.owl-carousel {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-content: center;
				justify-content: center;

				.owl-stage-outer {
					width: 100%;
				}

				.owl-item {
					transition: all 0.3s ease-in;
				}
			
				.owl-item.active.center {
					transform: scale(1);
					transition: all 0.3s ease-in;
				}	

				.owl-nav {
					position: absolute !important;
					margin-top: 0;
					top: 40%;
					left: 0;
					transform: translateY(-50%);
					width: 100%;
					pointer-events: none;
					justify-content: space-between;
					display: flex;
					align-items: center;
		
					.owl-prev, .owl-next {
						pointer-events: auto;
						background-color: darken(@color2, 10%);
						border-radius: 50%;
						background-image: url(../img/icons/chevron.svg);
						background-position: center;
						background-size: 25%;
						background-repeat: no-repeat;
						padding: 0;
						margin: 0;
						display: block;
						height: 50px;
						width: 50px;
						outline: none;
					}
		
					.owl-next {
						transform: rotate(-180deg);
					}
				}
	
				.ikona-container {
	
					.ikona-img {
						width: 250px;
						height: 250px;
					}
				}
			}			

		}

	}
}

section.faktury-zdobienia-section-content {
	padding: 100px 0px 0px 0px;
	background-color: #fff;;

	.container {

		.faktury-container {
			justify-content: center;

			.faktury-img {
				width: 150px;
				height: 150px;
				overflow: hidden;
				margin: 5px;

				img {
					width: 150px;
					height: auto;
					object-fit: contain;
				}
			}
		}

	}
}

section.dodatkowe-uslugi-section-content {
	padding: 100px 0px 100px 0px;
	background-color: #fff;;

	.container {

		.dodatkowe-uslugi-container {

			.dodatkowe {
				flex-direction: column;
			}
		}

	}
}

}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
	nav.section-header {
		.header-flexbox {
			.flexbox-inner {
				.flexbox-social-media {
					display: none;
				}
			}
		}
	}

/////////////////////// DODANE PRZEZ J.W PODSTRONA TECHNOLOGIE

section.mozliwosci-techniczne-section-content {
	padding: 100px 0px 00px 0px;

	.container {
		flex-direction: column;
	
		.kolory-zlota {
				padding-top: 100px;
		}
	}
}

section.rozmiary-section-content {
	padding: 100px 0px 0px 0px;

	.container {

		.rozmiar-container {
			flex-flow: column;

			.rozmiar-kraj {
				p{
					font-size: 30px;
				}
			}

			.rozmiar-kreska {
				width: 30px;
			}

		}

		.rozmiar-cta {
			flex-direction: column;
		}
	}
}

section.proby-section-content {
	padding: 100px 0px 0px 0px;
	background-color: #F3F2ED;;

	.container {

		.proby-container {
			flex-flow: row wrap;
			}
	}	
}

section.kamienie-section-content {
	padding: 100px 0px 50px 0px;

	.container {

		.kamienie-container {
			justify-content: space-around;
			flex-flow: row wrap;
			gap: 10px;
			
			.kamien {
				width: auto;
				.kamien-img {
					width: 50px;
					height: 50px;
					margin: 0 auto 20px auto;
				}

				p {
					font-size: 24px;
				}
			}

			.kamien-kreska {
				width: 0;
			}
		}
	}
}

section.oprawy-section-content {
	padding: 100px 0px 0px 0px;
	background-color: #fff;;

	.container {

		.oprawy-slider-container {

			.owl-carousel {
				margin-top: 100px;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-content: center;
				justify-content: center;

				.owl-stage-outer {
					width: 100%;
				}

				.owl-item {
					transition: all 0.3s ease-in;
				}
			
				.owl-item.active.center {
					transform: scale(1);
					transition: all 0.3s ease-in;
				}	

				.owl-nav {
					position: absolute !important;
					margin-top: 0;
					top: 40%;
					left: 0;
					transform: translateY(-50%);
					width: 100%;
					pointer-events: none;
					justify-content: space-between;
					display: flex;
					align-items: center;
		
					.owl-prev, .owl-next {
						pointer-events: auto;
						background-color: darken(@color2, 10%);
						border-radius: 50%;
						background-image: url(../img/icons/chevron.svg);
						background-position: center;
						background-size: 25%;
						background-repeat: no-repeat;
						padding: 0;
						margin: 0;
						display: block;
						height: 50px;
						width: 50px;
						outline: none;
					}
		
					.owl-next {
						transform: rotate(-180deg);
					}
				}
			}			
		}
	}
}

section.grawery-section-content {
	padding: 100px 0px 0px 0px;
	background-color: #fff;;

	.container {

		.grawery-container {
			flex-direction: column;

			.grawer {
				flex-direction: column;
				gap: 20px;
				margin-bottom: 20px;

				img {
					width: 100%;
				}
			}
		}
	}
}

section.ikony-slider-section-content {
	padding: 100px 0px 0px 0px;

	.container {

		.ikony-slider-container {

			.owl-carousel {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-content: center;
				justify-content: center;

				.owl-stage-outer {
					width: 100%;
				}

				.owl-item {
					transition: all 0.3s ease-in;
				}
			
				.owl-item.active.center {
					transform: scale(1);
					transition: all 0.3s ease-in;
				}	

				.owl-nav {
					position: absolute !important;
					margin-top: 0;
					top: 40%;
					left: 0;
					transform: translateY(-50%);
					width: 100%;
					pointer-events: none;
					justify-content: space-between;
					display: flex;
					align-items: center;
		
					.owl-prev, .owl-next {
						pointer-events: auto;
						background-color: darken(@color2, 10%);
						border-radius: 50%;
						background-image: url(../img/icons/chevron.svg);
						background-position: center;
						background-size: 25%;
						background-repeat: no-repeat;
						padding: 0;
						margin: 0;
						display: block;
						height: 50px;
						width: 50px;
						outline: none;
					}
		
					.owl-next {
						transform: rotate(-180deg);
					}
				}
	
				.ikona-container {
	
					.ikona-img {
						width: 250px;
						height: 250px;
					}
				}
			}			

		}

	}
}

section.faktury-zdobienia-section-content {
	padding: 100px 0px 0px 0px;
	background-color: #fff;;

	.container {

		.faktury-container {
			justify-content: center;

			.faktury-img {
				width: 150px;
				height: 150px;
				overflow: hidden;
				margin: 5px;

				img {
					width: 150px;
					height: auto;
					object-fit: contain;
				}
			}
		}

	}
}

section.dodatkowe-uslugi-section-content {
	padding: 100px 0px 100px 0px;
	background-color: #fff;;

	.container {

		.dodatkowe-uslugi-container {

			.dodatkowe {
				flex-direction: column;
			}
		}

	}
}

}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 450px) {
	.products-flexbox {
		.product-service {
			margin-top: 8%;
			width: 48%;
		}

		&.small-margin {
			.product-service {
				margin-top: 4%;
			}
		}
	}

	section.o-firmie-section-content {
		.content-history {
			.nav-tabs {
				.nav-item {
					.nav-link {
						font-size: 16px;
						font-weight: 700;
					}

					.divider {
						margin-top: 2px;
						margin-left: 12px;
						margin-right: 12px;
						width: 28px;

						&:before, &:after {
							height: 5px;
							width: 5px;
						}
					}
				}
			}
		}
	}

	section.produkt-section-content {
		.content-navigation {
			.nav-prev, .nav-next {
				.nav-icon {
					height: 35px;
					width: 35px;
					margin-right: 7px;
				}

				span {
					font-size: 9px;
					letter-spacing: 0.5px;
				}
			}

			.nav-next {
				margin-left: 0;

				.nav-icon {
					margin-left: 7px;
				}
			}
		}	
	}

	section.kategoria-section-content {
		.content-categories {
			.category-service {
				.service-image {
					height: 48px;
				}

				.service-name {
					margin-left: 6px;
					font-size: 15px;
				}
			}

			.btn-small {
				padding: 6px 20px;
			}
		}

		.products-flexbox {
			margin-top: 0;
		}
	}


/////////////////////// DODANE PRZEZ J.W PODSTRONA TECHNOLOGIE

section.mozliwosci-techniczne-section-content {
	padding: 100px 0px 00px 0px;

	.container {
		flex-direction: column;
	
		.kolory-zlota {
				padding-top: 100px;
		}
	}
}

section.rozmiary-section-content {
	padding: 100px 0px 0px 0px;

	.container {

		.rozmiar-container {
			flex-flow: column;
		}

		.rozmiar-cta {
			flex-direction: column;
		}
	}
}

section.proby-section-content {
	padding: 100px 0px 0px 0px;
	background-color: #F3F2ED;;

	.container {

		.proby-container {
			flex-flow: row wrap;
			}
	}	
}

section.kamienie-section-content {
	padding: 100px 0px 50px 0px;

	.container {

		.kamienie-container {
			flex-flow: column wrap;
			justify-content: space-between;
			gap: 60px;

			.kamien {
				width: auto;
				.kamien-img {
					width: 50px;
					height: 50px;
					margin: 0 auto 20px auto;
				}

				p {
					font-size: 30px;
				}
			}
			
			.kamien-kreska {
				width: 30px;
			}
		}
	}
}

section.oprawy-section-content {
	padding: 100px 0px 0px 0px;
	background-color: #fff;;

	.container {

		.oprawy-slider-container {

			.owl-carousel {
				margin-top: 100px;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-content: center;
				justify-content: center;

				.owl-stage-outer {
					width: 100%;
				}

				.owl-item {
					transition: all 0.3s ease-in;
				}
			
				.owl-item.active.center {
					transform: scale(1);
					transition: all 0.3s ease-in;
				}	

				.owl-nav {
					position: absolute !important;
					margin-top: 0;
					top: 40%;
					left: 0;
					transform: translateY(-50%);
					width: 100%;
					pointer-events: none;
					justify-content: space-between;
					display: flex;
					align-items: center;
		
					.owl-prev, .owl-next {
						pointer-events: auto;
						background-color: darken(@color2, 10%);
						border-radius: 50%;
						background-image: url(../img/icons/chevron.svg);
						background-position: center;
						background-size: 25%;
						background-repeat: no-repeat;
						padding: 0;
						margin: 0;
						display: block;
						height: 50px;
						width: 50px;
						outline: none;
					}
		
					.owl-next {
						transform: rotate(-180deg);
					}
				}
			}			
		}
	}
}

section.grawery-section-content {
	padding: 100px 0px 0px 0px;
	background-color: #fff;;

	.container {

		.grawery-container {
			flex-direction: column;

			.grawer {
				flex-direction: column;
				gap: 20px;
				margin-bottom: 20px;

				img {
					width: 100%;
				}
			}
		}
	}
}

section.ikony-slider-section-content {
	padding: 100px 0px 0px 0px;

	.container {

		.ikony-slider-container {

			.owl-carousel {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-content: center;
				justify-content: center;

				.owl-stage-outer {
					width: 100%;
				}

				.owl-item {
					transition: all 0.3s ease-in;
				}
			
				.owl-item.active.center {
					transform: scale(1);
					transition: all 0.3s ease-in;
				}	

				.owl-nav {
					position: absolute !important;
					margin-top: 0;
					top: 40%;
					left: 0;
					transform: translateY(-50%);
					width: 100%;
					pointer-events: none;
					justify-content: space-between;
					display: flex;
					align-items: center;
		
					.owl-prev, .owl-next {
						pointer-events: auto;
						background-color: darken(@color2, 10%);
						border-radius: 50%;
						background-image: url(../img/icons/chevron.svg);
						background-position: center;
						background-size: 25%;
						background-repeat: no-repeat;
						padding: 0;
						margin: 0;
						display: block;
						height: 50px;
						width: 50px;
						outline: none;
					}
		
					.owl-next {
						transform: rotate(-180deg);
					}
				}
	
				.ikona-container {
	
					.ikona-img {
						width: 250px;
						height: 250px;
					}
				}
			}			

		}

	}
}

section.faktury-zdobienia-section-content {
	padding: 100px 0px 0px 0px;
	background-color: #fff;;

	.container {

		.faktury-container {
			justify-content: space-around;
			margin: 5px;


			.faktury-img {
				width: 150px;
				height: 150px;
				overflow: hidden;
				margin: 5px;

				img {
					width: 150px;
					height: auto;
					object-fit: contain;
				}
			}
		}

	}
}

section.dodatkowe-uslugi-section-content {
	padding: 100px 0px 100px 0px;
	background-color: #fff;;

	.container {

		.dodatkowe-uslugi-container {

			.dodatkowe {
				flex-direction: column;
			}
		}

	}
}

}